import { openBlock as g, createElementBlock as S, createElementVNode as W, defineComponent as Be, ref as V, inject as ve, computed as C, unref as u, renderSlot as K, createCommentVNode as b, normalizeClass as fe, withKeys as de, createBlock as le, withModifiers as Ue, reactive as Kt, onMounted as Oe, normalizeStyle as ft, toDisplayString as ge, Fragment as se, renderList as ye, createTextVNode as Fe, createVNode as je, Transition as yt, withCtx as ae, nextTick as St, onBeforeUpdate as ba, onUnmounted as un, createSlots as Pe, useSlots as dn, withDirectives as $a, mergeProps as Ae, normalizeProps as Le, guardReactiveProps as nt, vShow as Da, isRef as tt, resolveDynamicComponent as Vt, watch as Mt, getCurrentScope as Ma, onScopeDispose as Sa, toRef as Ct, provide as dt, Teleport as Aa } from 'vue';
import { getMonth as me, getYear as ie, isEqual as In, setHours as Yn, setMinutes as En, setSeconds as Fn, setMilliseconds as cn, isValid, setMonth as Ot, setYear as lt, getHours as Re, getMinutes as Ve, getSeconds as Ge, format as $n, isAfter as fn, isBefore as mn, set as Ce, parseISO as Pa, parse as ln, isDate as Ca, add as Kn, sub as Ta, startOfWeek as Hn, endOfWeek as _a, addMonths as vt, getDay as Ra, addDays as $t, subMonths as Et, addYears as Va, subYears as Ba, getISOWeek as Oa, differenceInCalendarDays as Na, eachDayOfInterval as Dn } from 'o365.lib.date-fns.js';
const Bt = isValid;

const st = (e, a) => {
  const n = e.__vccOpts || e;
  for (const [t, c] of a)
    n[t] = c;
  return n;
}, Ia = {}, Ya = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, Ea = /* @__PURE__ */ W("path", { d: "M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z" }, null, -1), Fa = /* @__PURE__ */ W("path", { d: "M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), Ka = /* @__PURE__ */ W("path", { d: "M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), Ha = /* @__PURE__ */ W("path", { d: "M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z" }, null, -1), Wa = [
  Ea,
  Fa,
  Ka,
  Ha
];
function Ua(e, a) {
  return g(), S("svg", Ya, Wa);
}

const Ht = /* @__PURE__ */ st(Ia, [["render", Ua]]), La = {}, Ga = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, ja = /* @__PURE__ */ W("path", { d: "M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z" }, null, -1), za = /* @__PURE__ */ W("path", { d: "M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z" }, null, -1), Xa = [
  ja,
  za
];
function qa(e, a) {
  return g(), S("svg", Ga, Xa);
}
const Ja = /* @__PURE__ */ st(La, [["render", qa]]), Za = {}, Qa = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, xa = /* @__PURE__ */ W("path", { d: "M20.943 23.057l-7.057-7.057c0 0 7.057-7.057 7.057-7.057 0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z" }, null, -1), el = [
  xa
];
function tl(e, a) {
  return g(), S("svg", Qa, el);
}
const Mn = /* @__PURE__ */ st(Za, [["render", tl]]), nl = {}, al = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, ll = /* @__PURE__ */ W("path", { d: "M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885l7.057 7.057c0 0-7.057 7.057-7.057 7.057-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0z" }, null, -1), rl = [
  ll
];
function sl(e, a) {
  return g(), S("svg", al, rl);
}
const Sn = /* @__PURE__ */ st(nl, [["render", sl]]), ol = {}, ul = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, il = /* @__PURE__ */ W("path", { d: "M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z" }, null, -1), dl = /* @__PURE__ */ W("path", { d: "M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z" }, null, -1), cl = [
  il,
  dl
];
function fl(e, a) {
  return g(), S("svg", ul, cl);
}
const Wn = /* @__PURE__ */ st(ol, [["render", fl]]), ml = {}, vl = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, yl = /* @__PURE__ */ W("path", { d: "M24.943 19.057l-8-8c-0.521-0.521-1.365-0.521-1.885 0l-8 8c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l7.057-7.057c0 0 7.057 7.057 7.057 7.057 0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z" }, null, -1), pl = [
  yl
];
function hl(e, a) {
  return g(), S("svg", vl, pl);
}
const Un = /* @__PURE__ */ st(ml, [["render", hl]]), gl = {}, wl = {
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  class: "dp__icon"
}, kl = /* @__PURE__ */ W("path", { d: "M7.057 12.943l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-7.057 7.057c0 0-7.057-7.057-7.057-7.057-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z" }, null, -1), bl = [
  kl
];
function $l(e, a) {
  return g(), S("svg", wl, bl);
}
const Ln = /* @__PURE__ */ st(gl, [["render", $l]]), An = (e, a) => {
  const n = ln(e, a.slice(0, e.length), new Date());
  return Bt(n) && Ca(n) ? n : null;
}, zt = (e, a) => {
  if (typeof a == "string")
    return An(e, a);
  if (Array.isArray(a)) {
    let n = null;
    for (let t = 0; t < a.length && (n = An(e, a[t]), !n); t++)
      ;
    return n;
  }
  return typeof a == "function" ? a(e) : null;
}, Ye = (e) => {
  let a = new Date(JSON.parse(JSON.stringify(e)));
  return a = Yn(a, 0), a = En(a, 0), a = Fn(a, 0), a = cn(a, 0), a;
}, rn = (e) => Array.isArray(e) ? Bt(e[0]) && (e[1] ? Bt(e[1]) : !0) : e ? Bt(e) : !1, Se = (e, a, n, t) => {
  let c = e ? new Date(e) : new Date();
  return (a || a === 0) && (c = Yn(c, +a)), (n || n === 0) && (c = En(c, +n)), (t || t === 0) && (c = Fn(c, +t)), cn(c, 0);
}, Dl = (e) => {
  const a = vt(e, 1);
  return { month: me(a), year: ie(a) };
}, rt = (e, a, n) => {
  let t = e ? new Date(e) : new Date();
  return (a || a === 0) && (t = Ot(t, a)), n && (t = lt(t, n)), t;
}, Pn = (e, a) => e ? `HH:mm${a ? ":ss" : ""}` : `hh:mm${a ? ":ss" : ""} aa`, Gn = (e, a, n, t, c, m, v, y) => e || (t ? "MM/yyyy" : c ? Pn(a, n) : m ? "MM/dd/yyyy" : v ? "yyyy" : y ? `MM/dd/yyyy, ${Pn(a, n)}` : "MM/dd/yyyy"), Xt = (e) => {
  const a = e || new Date();
  return {
    hours: Re(a),
    minutes: Ve(a),
    seconds: Ge(a)
  };
}, Nt = (e) => ({ month: me(e), year: ie(e) }), Cn = (e) => Array.isArray(e) ? [Nt(e[0]), e[1] ? Nt(e[1]) : null] : Nt(e), sn = (e) => Array.isArray(e) ? [Xt(e[0]), Xt(e[1])] : Xt(e), qt = (e, a, n) => n ? $n(e, a, { locale: n }) : $n(e, a), mt = (e, a, n, t, c) => Array.isArray(e) ? `${qt(e[0], a, n)} ${c && !e[1] ? "" : t || "-"} ${e[1] ? qt(e[1], a, n) : ""}` : qt(e, a, n), De = (e, a) => !e || !a ? !1 : fn(Ye(e), Ye(a)), ke = (e, a) => !e || !a ? !1 : mn(Ye(e), Ye(a)), ue = (e, a) => !e || !a ? !1 : In(Ye(e), Ye(a)), Ml = (e, a) => Kn(Ce(new Date(), e), a), Sl = (e, a) => Ta(Ce(new Date(), e), a), Jt = (e) => Ce(new Date(), { hours: Re(e), minutes: Ve(e), seconds: Ge(e) }), Tn = (e) => Ce(new Date(), {
  hours: +e.hours || 0,
  minutes: +e.minutes || 0,
  seconds: +e.seconds || 0
}), ct = (e, a, n, t) => {
  if (!e)
    return !0;
  if (t) {
    const c = n === "max" ? mn(e, a) : fn(e, a), m = { seconds: 0, milliseconds: 0 };
    return c || In(Ce(e, m), Ce(a, m));
  }
  return n === "max" ? e.getTime() <= a.getTime() : e.getTime() >= a.getTime();
}, on = (e, a, n, t, c) => {
  let m = !0;
  if (!e)
    return !0;
  const v = !c && !t ? Array.isArray(e) ? [e[0] ? Jt(e[0]) : null, e[1] ? Jt(e[1]) : null] : Jt(e) : e;
  if (a || t) {
    const y = a ? Tn(a) : new Date(t);
    Array.isArray(v) ? m = ct(v[0], y, "max", !!t) && ct(v[1], y, "max", !!t) : m = ct(v, y, "max", !!t);
  }
  if (n || c) {
    const y = n ? Tn(n) : new Date(c);
    Array.isArray(v) ? m = ct(v[0], y, "min", !!c) && ct(v[1], y, "min", !!c) && m : m = ct(v, y, "min", !!c) && m;
  }
  return m;
}, Al = (e, a, n) => {
  let t = !0;
  return a && n && (t = De(new Date(e), new Date(a)) && ke(new Date(e), new Date(n))), a && (t = De(new Date(e), new Date(a))), n && (t = ke(new Date(e), new Date(n))), t;
}, Ie = (e) => e instanceof Date ? e : Pa(e), Zt = (e, a) => {
  if (a) {
    const t = new Date().getTimezoneOffset() * 6e4;
    return new Date(e.getTime() - t).toISOString();
  }
  const n = Date.UTC(
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate(),
    e.getUTCHours(),
    e.getUTCMinutes(),
    e.getUTCSeconds()
  );
  return new Date(n).toISOString();
}, jn = (e, a, n) => e && e[0] && e[1] ? De(n, e[0]) && ke(n, e[1]) : e && e[0] && a ? De(n, e[0]) && ke(n, a) || ke(n, e[0]) && De(n, a) : !1, Tt = (e, a) => {
  const n = Hn(e, { weekStartsOn: a }), t = _a(e, { weekStartsOn: a });
  return [n, t];
}, zn = (e, a) => Array.isArray(a) ? a.some((n) => ue(Ie(new Date(n)), Ie(e))) : a(e), Pl = (e, a, n, t, c, m, v, y) => {
  const h = n ? De(Ie(e), Ie(n)) : !1, M = a ? ke(Ie(e), Ie(a)) : !1, R = zn(e, t), Y = (m.months.length ? m.months.map((l) => +l) : []).includes(me(e)), O = v.length ? v.some((l) => +l === Ra(e)) : !1, L = c.length ? !c.some((l) => ue(Ie(l), Ie(e))) : !1, T = ie(e), te = T < +y[0] || T > +y[1];
  return !(h || M || R || Y || te || O || L);
}, Xn = (e, a, n, t, c, m, v) => ({
  validate: (h) => Pl(h, e, a, n, t, c, m, v)
}), ze = Symbol(), Wt = Symbol(), vn = Symbol(), qn = Symbol(), Jn = Symbol(), Xe = Symbol(), yn = {
  disabled: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  autoApply: { type: Boolean, default: !1 },
  inline: { type: Boolean, default: !1 },
  textInput: { type: Boolean, default: !1 }
}, pn = {
  range: { type: Boolean, default: !1 },
  uid: { type: String, default: null }
}, Zn = {
  enableSeconds: { type: Boolean, default: !1 },
  is24: { type: Boolean, default: !0 },
  noHoursOverlay: { type: Boolean, default: !1 },
  noMinutesOverlay: { type: Boolean, default: !1 },
  noSecondsOverlay: { type: Boolean, default: !1 },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  secondsGridIncrement: { type: [String, Number], default: 5 },
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  secondsIncrement: { type: [Number, String], default: 1 }
}, Qn = {
  ...Zn,
  fixedStart: { type: Boolean, default: !1 },
  fixedEnd: { type: Boolean, default: !1 },
  timePicker: { type: Boolean, default: !1 }
}, xn = {
  name: { type: String, default: null },
  placeholder: { type: String, default: "" },
  hideInputIcon: { type: Boolean, default: !1 },
  clearable: { type: Boolean, default: !0 },
  state: { type: Boolean, default: null },
  required: { type: Boolean, default: !1 },
  autocomplete: { type: String, default: "off" },
  inputClassName: { type: String, default: null },
  inlineWithInput: { type: Boolean, default: !1 },
  textInputOptions: { type: Object, default: () => null }
}, ea = {
  minTime: { type: Object, default: null },
  maxTime: { type: Object, default: null }
}, hn = {
  minDate: { type: [Date, String], default: null },
  maxDate: { type: [Date, String], default: null }
}, ta = {
  selectText: { type: String, default: "Select" },
  cancelText: { type: String, default: "Cancel" },
  previewFormat: {
    type: [String, Function],
    default: () => ""
  },
  multiDates: { type: Boolean, default: !1 },
  partialRange: { type: Boolean, default: !0 },
  ...ea
}, gn = {
  monthPicker: { type: Boolean, default: !1 },
  customProps: { type: Object, default: null },
  yearPicker: { type: Boolean, default: !1 },
  modelAuto: { type: Boolean, default: !1 }
}, na = {
  locale: { type: String, default: "en-Us" },
  weekNumName: { type: String, default: "W" },
  weekStart: { type: [Number, String], default: 1 },
  weekNumbers: { type: Boolean, default: !1 },
  calendarClassName: { type: String, default: null },
  noSwipe: { type: Boolean, default: !1 },
  monthChangeOnScroll: { type: [Boolean, String], default: !0 },
  dayNames: {
    type: [Function, Array],
    default: null
  }
}, aa = {
  ...Qn,
  ...ta,
  ...gn,
  ...hn,
  ...na,
  ...pn,
  vertical: { type: Boolean, default: !1 },
  disableMonthYearSelect: { type: Boolean, default: !1 },
  menuClassName: { type: String, default: null },
  yearRange: { type: Array, default: () => [1900, 2100] },
  multiCalendarsSolo: { type: Boolean, default: !1 },
  calendarCellClassName: { type: String, default: null },
  enableTimePicker: { type: Boolean, default: !0 },
  autoApply: { type: Boolean, default: !1 },
  disabledDates: { type: [Array, Function], default: () => [] },
  monthNameFormat: { type: String, default: "short" },
  startDate: { type: [Date, String], default: null },
  startTime: { type: [Object, Array], default: null },
  monthYearComponent: { type: Object, default: null },
  timePickerComponent: { type: Object, default: null },
  actionRowComponent: { type: Object, default: null },
  hideOffsetDates: { type: Boolean, default: !1 },
  autoRange: { type: [Number, String], default: null },
  noToday: { type: Boolean, default: !1 },
  disabledWeekDays: { type: Array, default: () => [] },
  allowedDates: { type: Array, default: () => [] },
  showNowButton: { type: Boolean, default: !1 },
  nowButtonLabel: { type: String, default: "Now" },
  markers: { type: Array, default: () => [] },
  modeHeight: { type: [Number, String], default: 255 },
  escClose: { type: Boolean, default: !0 },
  spaceConfirm: { type: Boolean, default: !0 },
  monthChangeOnArrows: { type: Boolean, default: !0 },
  presetRanges: { type: Array, default: () => [] },
  flow: { type: Array, default: () => [] },
  preventMinMaxNavigation: { type: Boolean, default: !1 },
  minRange: { type: [Number, String], default: null },
  maxRange: { type: [Number, String], default: null },
  multiDatesLimit: { type: [Number, String], default: null },
  reverseYears: { type: Boolean, default: !1 },
  keepActionRow: { type: Boolean, default: !1 },
  weekPicker: { type: Boolean, default: !1 },
  filters: { type: Object, default: () => ({}) },
  arrowNavigation: { type: Boolean, default: !1 },
  multiStatic: { type: Boolean, default: !0 },
  disableTimeRangeValidation: { type: Boolean, default: !1 },
  highlight: {
    type: [Array, Function],
    default: null
  },
  teleportCenter: { type: Boolean, default: !1 }
}, Cl = {
  ...xn,
  ...yn,
  ...aa,
  multiCalendars: { type: [Boolean, Number, String], default: null },
  modelValue: { type: [String, Date, Array, Object, Number], default: null },
  modelType: { type: String, default: null },
  position: { type: String, default: "center" },
  dark: { type: Boolean, default: !1 },
  format: {
    type: [String, Function],
    default: () => null
  },
  closeOnScroll: { type: Boolean, default: !1 },
  autoPosition: { type: Boolean, default: !0 },
  closeOnAutoApply: { type: Boolean, default: !0 },
  teleport: { type: [String, Object], default: "body" },
  altPosition: { type: [Boolean, Function], default: !1 },
  transitions: { type: [Boolean, Object], default: !0 },
  formatLocale: { type: Object, default: null },
  utc: { type: [Boolean, String], default: !1 },
  ariaLabels: { type: Object, default: () => ({}) },
  offset: { type: [Number, String], default: 10 }
}, la = {
  range: { type: Boolean, default: !1 },
  multiCalendars: { type: Number, default: 0 },
  internalModelValue: { type: [Date, Array], default: null }
}, ra = {
  ...gn,
  ...la,
  vertical: { type: Boolean, default: !1 },
  month: { type: Number, default: 0 },
  year: { type: Number, default: 0 },
  instance: { type: Number, default: 1 }
}, Tl = ["aria-label", "aria-disabled", "aria-readonly"], _l = {
  key: 1,
  class: "dp__input_wrap"
}, Rl = ["id", "name", "placeholder", "disabled", "readonly", "required", "value", "autocomplete", "onKeydown"], Vl = {
  key: 2,
  class: "dp__input_icon"
}, Bl = {
  key: 4,
  class: "dp__clear_icon"
}, Ol = /* @__PURE__ */ Be({
  __name: "DatepickerInput",
  props: {
    ...xn,
    ...yn,
    ...pn,
    inputValue: { type: String, default: "" },
    inline: { type: Boolean, default: !1 },
    isMenuOpen: { type: Boolean, default: !1 },
    pattern: { type: String, default: "" }
  },
  emits: [
    "clear",
    "open",
    "update:inputValue",
    "setInputDate",
    "close",
    "selectDate",
    "setEmptyDate",
    "toggle",
    "focus-prev",
    "focus",
    "blur"
  ],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = V(), m = V(null), v = V(!1), y = ve(ze), h = C(
      () => ({
        dp__pointer: !t.disabled && !t.readonly && !t.textInput,
        dp__disabled: t.disabled,
        dp__input_readonly: !t.textInput,
        dp__input: !0,
        dp__input_icon_pad: !t.hideInputIcon,
        dp__input_valid: t.state,
        dp__input_invalid: t.state === !1,
        dp__input_focus: v.value || t.isMenuOpen,
        dp__input_reg: !t.textInput,
        [t.inputClassName]: !!t.inputClassName
      })
    ), M = (o) => {
      var U;
      const { value: F } = o.target, { format: J, rangeSeparator: _ } = t.textInputOptions;
      if (F !== "") {
        if (((U = t.textInputOptions) == null ? void 0 : U.openMenu) && !t.isMenuOpen && n("open"), t.range) {
          const [z, Z] = F.split(`${_}`);
          if (z && Z) {
            const Q = zt(z.trim(), J || t.pattern), j = zt(Z.trim(), J || t.pattern);
            c.value = Q && j ? [Q, j] : null;
          }
        } else
          c.value = zt(F, J || t.pattern);
        n("setInputDate", c.value);
      } else
        n("setInputDate", null), t.autoApply && (n("setEmptyDate"), c.value = null);
      n("update:inputValue", F);
    }, R = () => {
      var o, F;
      ((o = t.textInputOptions) == null ? void 0 : o.enterSubmit) && rn(c.value) && t.inputValue !== "" ? (n("setInputDate", c.value, !0), c.value = null) : ((F = t.textInputOptions) == null ? void 0 : F.enterSubmit) && t.inputValue === "" && (c.value = null, n("clear"));
    }, B = () => {
      var o, F;
      ((o = t.textInputOptions) == null ? void 0 : o.tabSubmit) && rn(c.value) && t.inputValue !== "" ? (n("setInputDate", c.value, !0), c.value = null) : ((F = t.textInputOptions) == null ? void 0 : F.tabSubmit) && t.inputValue === "" && (c.value = null, n("clear"));
    }, Y = () => {
      v.value = !0, n("focus");
    }, O = (o) => {
      var F;
      o.preventDefault(), o.stopImmediatePropagation(), o.stopPropagation(), t.textInput && ((F = t.textInputOptions) == null ? void 0 : F.openMenu) ? t.isMenuOpen ? t.textInputOptions.enterSubmit && n("selectDate") : n("open") : t.textInput || n("toggle");
    }, L = () => {
      v.value = !1, t.isMenuOpen || n("blur"), t.autoApply && t.textInput && c.value && (n("setInputDate", c.value), n("selectDate"), c.value = null);
    }, T = () => {
      n("clear");
    }, te = (o) => {
      t.textInput || o.preventDefault();
    };
    return a({
      focusInput: () => {
        m.value && m.value.focus({ preventScroll: !0 });
      }
    }), (o, F) => (g(), S("div", {
      onClick: O,
      "aria-label": u(y).input,
      role: "textbox",
      "aria-multiline": "false",
      "aria-disabled": o.disabled,
      "aria-readonly": o.readonly
    }, [
      o.$slots.trigger && !o.$slots["dp-input"] && !e.inline ? K(o.$slots, "trigger", { key: 0 }) : b("", !0),
      !o.$slots.trigger && (!e.inline || o.inlineWithInput) ? (g(), S("div", _l, [
        o.$slots["dp-input"] && !o.$slots.trigger && !e.inline ? K(o.$slots, "dp-input", {
          key: 0,
          value: e.inputValue,
          onInput: M,
          onEnter: R,
          onTab: B,
          onClear: T
        }) : b("", !0),
        o.$slots["dp-input"] ? b("", !0) : (g(), S("input", {
          key: 1,
          ref_key: "inputRef",
          ref: m,
          id: o.uid ? `dp-input-${o.uid}` : void 0,
          name: o.name,
          class: fe(u(h)),
          placeholder: o.placeholder,
          disabled: o.disabled,
          readonly: o.readonly,
          required: o.required,
          value: e.inputValue,
          autocomplete: o.autocomplete,
          onInput: M,
          onKeydown: [
            de(O, ["enter"]),
            de(B, ["tab"])
          ],
          onBlur: L,
          onFocus: Y,
          onKeypress: te
        }, null, 42, Rl)),
        o.$slots["input-icon"] && !o.hideInputIcon ? (g(), S("span", Vl, [
          K(o.$slots, "input-icon")
        ])) : b("", !0),
        !o.$slots["input-icon"] && !o.hideInputIcon && !o.$slots["dp-input"] ? (g(), le(u(Ht), {
          key: 3,
          class: "dp__input_icon dp__input_icons"
        })) : b("", !0),
        o.$slots["clear-icon"] && e.inputValue && o.clearable && !o.disabled && !o.readonly ? (g(), S("span", Bl, [
          K(o.$slots, "clear-icon", { clear: T })
        ])) : b("", !0),
        o.clearable && !o.$slots["clear-icon"] && e.inputValue && !o.disabled && !o.readonly ? (g(), le(u(Ja), {
          key: 5,
          class: "dp__clear_icon dp__input_icons",
          onClick: Ue(T, ["stop", "prevent"])
        }, null, 8, ["onClick"])) : b("", !0)
      ])) : b("", !0)
    ], 8, Tl));
  }
}), Nl = (e, a, n) => {
  const t = new Date(JSON.parse(JSON.stringify(e))), c = [];
  for (let m = 0; m < 7; m++) {
    const v = $t(t, m), y = me(v) !== a;
    c.push({
      text: n && y ? "" : v.getDate(),
      value: v,
      current: !y
    });
  }
  return c;
}, Il = (e, a, n, t) => {
  const c = [], m = new Date(a, e), v = new Date(a, e + 1, 0), y = Hn(m, { weekStartsOn: n }), h = (M) => {
    const R = Nl(M, e, t);
    if (c.push({ days: R }), !c[c.length - 1].days.some((B) => ue(Ye(B.value), Ye(v)))) {
      const B = $t(M, 7);
      h(B);
    }
  };
  return h(y), c;
}, Yl = (e, a = 3) => {
  const n = [];
  for (let t = 0; t < e.length; t += a)
    n.push([e[t], e[t + 1], e[t + 2]]);
  return n;
}, El = (e, a) => {
  const n = [1, 2, 3, 4, 5, 6, 7].map((m) => new Intl.DateTimeFormat(e, { weekday: "short", timeZone: "UTC" }).format(new Date(`2017-01-0${m}T00:00:00+00:00`)).slice(0, 2)), t = n.slice(0, a), c = n.slice(a + 1, n.length);
  return [n[a]].concat(...c).concat(...t);
}, Fl = (e) => {
  const a = [];
  for (let n = +e[0]; n <= +e[1]; n++)
    a.push({ value: +n, text: `${n}` });
  return a;
}, Kl = (e, a) => {
  const n = new Intl.DateTimeFormat(e, { month: a, timeZone: "UTC" });
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((c) => {
    const m = c < 10 ? `0${c}` : c;
    return new Date(`2017-${m}-01T00:00:00+00:00`);
  }).map((c, m) => ({
    text: n.format(c),
    value: m
  }));
}, Hl = (e) => [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11][e], Wl = () => ({
  enterSubmit: !0,
  tabSubmit: !0,
  openMenu: !0,
  rangeSeparator: " - "
}), Ul = (e) => Object.assign({ months: [], years: [], times: { hours: [], minutes: [], seconds: [] } }, e), Ll = (e) => {
  function n(t) {
    let c = "";
    const m = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", v = m.length;
    for (let y = 0; y < t; y++)
      c += m.charAt(Math.floor(Math.random() * v));
    return c + e;
  }
  return n(5);
}, we = (e) => {
  var n;
  const a = u(e);
  return (n = a == null ? void 0 : a.$el) != null ? n : a;
}, Gl = (e) => Object.assign({ type: "dot" }, e), _n = (e) => Object.assign(
  {
    menuAppear: "dp-menu-appear",
    open: "dp-slide-down",
    close: "dp-slide-up",
    next: "calendar-next",
    previous: "calendar-prev",
    vNext: "dp-slide-up",
    vPrevious: "dp-slide-down"
  },
  e
), jl = (e) => Object.assign(
  {
    toggleOverlay: "Toggle overlay",
    menu: "Datepicker menu",
    input: "Datepicker input",
    calendarWrap: "Calendar wrapper",
    calendarDays: "Calendar days",
    openTimePicker: "Open time picker",
    closeTimePicker: "Close time Picker",
    incrementValue: (a) => `Increment ${a}`,
    decrementValue: (a) => `Decrement ${a}`,
    openTpOverlay: (a) => `Open ${a} overlay`,
    amPmButton: "Switch AM/PM mode",
    openYearsOverlay: "Open years overlay",
    openMonthsOverlay: "Open months overlay",
    nextMonth: "Next month",
    prevMonth: "Previous month"
  },
  e
), sa = (e) => Array.isArray(e) ? !!e[0] && !!e[1] : !1, oe = Kt({
  monthYear: [],
  calendar: [],
  time: [],
  actionRow: [],
  selectionGrid: [],
  timePicker: {
    0: [],
    1: []
  },
  monthPicker: []
}), Qt = V(null), _t = V(!1), xt = V(!1), en = V(!1), tn = V(!1), $e = V(0), he = V(0), qe = () => {
  const e = C(() => _t.value ? [...oe.selectionGrid, oe.actionRow].filter((l) => l.length) : xt.value ? [
    ...oe.timePicker[0],
    ...oe.timePicker[1],
    tn.value ? [] : [Qt.value],
    oe.actionRow
  ].filter((l) => l.length) : en.value ? [...oe.monthPicker, oe.actionRow] : [oe.monthYear, ...oe.calendar, oe.time, oe.actionRow].filter((l) => l.length)), a = (l) => {
    $e.value = l ? $e.value + 1 : $e.value - 1;
    let o = null;
    e.value[he.value] && (o = e.value[he.value][$e.value]), o || ($e.value = l ? $e.value - 1 : $e.value + 1);
  }, n = (l) => {
    if (he.value === 0 && !l || he.value === e.value.length && l)
      return;
    he.value = l ? he.value + 1 : he.value - 1, e.value[he.value] ? e.value[he.value] && !e.value[he.value][$e.value] && $e.value !== 0 && ($e.value = e.value[he.value].length - 1) : he.value = l ? he.value - 1 : he.value + 1;
  }, t = (l) => {
    let o = null;
    e.value[he.value] && (o = e.value[he.value][$e.value]), o ? o.focus({ preventScroll: !_t.value }) : $e.value = l ? $e.value - 1 : $e.value + 1;
  }, c = () => {
    a(!0), t(!0);
  }, m = () => {
    a(!1), t(!1);
  }, v = () => {
    n(!1), t(!0);
  }, y = () => {
    n(!0), t(!0);
  }, h = (l, o) => {
    oe[o] = l;
  }, M = (l, o) => {
    oe[o] = l;
  }, R = () => {
    $e.value = 0, he.value = 0;
  };
  return {
    buildMatrix: h,
    buildMultiLevelMatrix: M,
    setTimePickerBackRef: (l) => {
      Qt.value = l;
    },
    setSelectionGrid: (l) => {
      _t.value = l, R(), l || (oe.selectionGrid = []);
    },
    setTimePicker: (l, o = !1) => {
      xt.value = l, tn.value = o, R(), l || (oe.timePicker[0] = [], oe.timePicker[1] = []);
    },
    setTimePickerElements: (l, o = 0) => {
      oe.timePicker[o] = l;
    },
    arrowRight: c,
    arrowLeft: m,
    arrowUp: v,
    arrowDown: y,
    clearArrowNav: () => {
      oe.monthYear = [], oe.calendar = [], oe.time = [], oe.actionRow = [], oe.selectionGrid = [], oe.timePicker[0] = [], oe.timePicker[1] = [], _t.value = !1, xt.value = !1, tn.value = !1, en.value = !1, R(), Qt.value = null;
    },
    setMonthPicker: (l) => {
      en.value = l, R();
    }
  };
}, zl = ["aria-label"], Xl = {
  class: "dp__calendar_header",
  role: "row"
}, ql = {
  key: 0,
  class: "dp__calendar_header_item",
  role: "gridcell"
}, Jl = /* @__PURE__ */ W("div", { class: "dp__calendar_header_separator" }, null, -1), Zl = ["aria-label"], Ql = {
  key: 0,
  role: "gridcell",
  class: "dp__calendar_item dp__week_num"
}, xl = { class: "dp__cell_inner" }, er = ["aria-selected", "aria-disabled", "onClick", "onKeydown", "onMouseover"], tr = /* @__PURE__ */ W("div", { class: "dp__arrow_bottom_tp" }, null, -1), nr = /* @__PURE__ */ Be({
  __name: "Calendar",
  props: {
    ...ra,
    ...na,
    mappedDates: { type: Array, default: () => [] },
    getWeekNum: {
      type: Function,
      default: () => ""
    },
    modeHeight: { type: [Number, String], default: 255 },
    specificMode: { type: Boolean, default: !1 }
  },
  emits: ["selectDate", "setHoverDate", "handleScroll", "mount", "handleSwipe"],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = V(null), m = V({ bottom: "", left: "", transform: "" }), v = V([]), y = V(null), h = V(!0), M = ve(Wt), R = ve(ze), B = ve(Xe), Y = V(""), O = V({ startX: 0, endX: 0, startY: 0, endY: 0 }), L = C(() => t.dayNames ? Array.isArray(t.dayNames) ? t.dayNames : t.dayNames(t.locale, +t.weekStart) : El(t.locale, +t.weekStart)), { buildMultiLevelMatrix: T } = qe();
    Oe(() => {
      n("mount", { cmp: "calendar", refs: v }), t.noSwipe || y.value && (y.value.addEventListener("touchstart", Z, { passive: !1 }), y.value.addEventListener("touchend", Q, { passive: !1 }), y.value.addEventListener("touchmove", j, { passive: !1 })), t.monthChangeOnScroll && y.value && y.value.addEventListener("wheel", i, { passive: !1 });
    });
    const te = (s, w) => {
      if (M != null && M.value) {
        const E = Ye(rt(new Date(), t.month, t.year));
        Y.value = De(Ye(rt(new Date(), s, w)), E) ? M.value[t.vertical ? "vNext" : "next"] : M.value[t.vertical ? "vPrevious" : "previous"], h.value = !1, St(() => {
          h.value = !0;
        });
      }
    }, l = C(
      () => ({
        dp__calendar_wrap: !0,
        [t.calendarClassName]: !!t.calendarClassName
      })
    ), o = C(() => (s) => {
      const w = Gl(s);
      return {
        dp__marker_dot: w.type === "dot",
        dp__marker_line: w.type === "line"
      };
    }), F = C(() => (s) => ue(s, c.value)), J = C(() => ({
      dp__calendar: !0,
      dp__calendar_next: t.multiCalendars > 0 && t.instance !== 0
    })), _ = C(() => t.specificMode ? { height: `${t.modeHeight}px` } : null), U = (s, w, E) => {
      var ee, I;
      if (n("setHoverDate", s), (I = (ee = s.marker) == null ? void 0 : ee.tooltip) != null && I.length) {
        const re = we(v.value[w][E]);
        if (re) {
          const { width: p, height: P } = re.getBoundingClientRect();
          m.value = {
            bottom: `${P}px`,
            left: `${p / 2}px`,
            transform: "translateX(-50%)"
          }, c.value = s.value;
        }
      }
    }, z = () => {
      c.value = null;
    }, Z = (s) => {
      O.value.startX = s.changedTouches[0].screenX, O.value.startY = s.changedTouches[0].screenY;
    }, Q = (s) => {
      O.value.endX = s.changedTouches[0].screenX, O.value.endY = s.changedTouches[0].screenY, D();
    }, j = (s) => {
      s.preventDefault();
    }, D = () => {
      const s = t.vertical ? "Y" : "X";
      Math.abs(O.value[`start${s}`] - O.value[`end${s}`]) > 10 && n("handleSwipe", O.value[`start${s}`] > O.value[`end${s}`] ? "right" : "left");
    }, $ = (s, w, E) => {
      s && (Array.isArray(v.value[w]) ? v.value[w][E] = s : v.value[w] = [s]), B != null && B.value && T(v.value, "calendar");
    }, i = (s) => {
      t.monthChangeOnScroll && (s.preventDefault(), n("handleScroll", s));
    };
    return a({ triggerTransition: te }), (s, w) => (g(), S("div", {
      class: fe(u(J))
    }, [
      W("div", {
        style: ft(u(_))
      }, [
        e.specificMode ? b("", !0) : (g(), S("div", {
          key: 0,
          ref_key: "calendarWrapRef",
          ref: y,
          class: fe(u(l)),
          role: "grid",
          "aria-label": u(R).calendarWrap
        }, [
          W("div", Xl, [
            s.weekNumbers ? (g(), S("div", ql, ge(s.weekNumName), 1)) : b("", !0),
            (g(!0), S(se, null, ye(u(L), (E, ee) => (g(), S("div", {
              class: "dp__calendar_header_item",
              role: "gridcell",
              key: ee
            }, [
              s.$slots["calendar-header"] ? K(s.$slots, "calendar-header", {
                key: 0,
                day: E,
                index: ee
              }) : b("", !0),
              s.$slots["calendar-header"] ? b("", !0) : (g(), S(se, { key: 1 }, [
                Fe(ge(E), 1)
              ], 64))
            ]))), 128))
          ]),
          Jl,
          je(yt, {
            name: Y.value,
            css: !!u(M)
          }, {
            default: ae(() => [
              h.value ? (g(), S("div", {
                key: 0,
                class: "dp__calendar",
                role: "grid",
                "aria-label": u(R).calendarDays
              }, [
                (g(!0), S(se, null, ye(e.mappedDates, (E, ee) => (g(), S("div", {
                  class: "dp__calendar_row",
                  role: "row",
                  key: ee
                }, [
                  s.weekNumbers ? (g(), S("div", Ql, [
                    W("div", xl, ge(e.getWeekNum(E.days)), 1)
                  ])) : b("", !0),
                  (g(!0), S(se, null, ye(E.days, (I, re) => (g(), S("div", {
                    role: "gridcell",
                    class: "dp__calendar_item",
                    ref_for: !0,
                    ref: (p) => $(p, ee, re),
                    key: re + ee,
                    "aria-selected": I.classData.dp__active_date || I.classData.dp__range_start || I.classData.dp__range_start,
                    "aria-disabled": I.classData.dp__cell_disabled,
                    tabindex: "0",
                    onClick: Ue((p) => s.$emit("selectDate", I), ["stop", "prevent"]),
                    onKeydown: de((p) => s.$emit("selectDate", I), ["enter"]),
                    onMouseover: (p) => U(I, ee, re),
                    onMouseleave: z
                  }, [
                    W("div", {
                      class: fe(["dp__cell_inner", I.classData])
                    }, [
                      s.$slots.day ? K(s.$slots, "day", {
                        key: 0,
                        day: +I.text,
                        date: I.value
                      }) : b("", !0),
                      s.$slots.day ? b("", !0) : (g(), S(se, { key: 1 }, [
                        Fe(ge(I.text), 1)
                      ], 64)),
                      I.marker ? (g(), S("div", {
                        key: 2,
                        class: fe(u(o)(I.marker)),
                        style: ft(I.marker.color ? { backgroundColor: I.marker.color } : {})
                      }, null, 6)) : b("", !0),
                      u(F)(I.value) ? (g(), S("div", {
                        key: 3,
                        class: "dp__marker_tooltip",
                        style: ft(m.value)
                      }, [
                        W("div", {
                          class: "dp__tooltip_content",
                          onClick: w[0] || (w[0] = Ue(() => {
                          }, ["stop"]))
                        }, [
                          (g(!0), S(se, null, ye(I.marker.tooltip, (p, P) => (g(), S("div", {
                            key: P,
                            class: "dp__tooltip_text"
                          }, [
                            s.$slots["marker-tooltip"] ? K(s.$slots, "marker-tooltip", {
                              key: 0,
                              tooltop: p,
                              day: I.value
                            }) : b("", !0),
                            s.$slots["marker-tooltip"] ? b("", !0) : (g(), S(se, { key: 1 }, [
                              W("div", {
                                class: "dp__tooltip_mark",
                                style: ft(p.color ? { backgroundColor: p.color } : {})
                              }, null, 4),
                              W("div", null, ge(p.text), 1)
                            ], 64))
                          ]))), 128)),
                          tr
                        ])
                      ], 4)) : b("", !0)
                    ], 2)
                  ], 40, er))), 128))
                ]))), 128))
              ], 8, Zl)) : b("", !0)
            ]),
            _: 3
          }, 8, ["name", "css"])
        ], 10, zl))
      ], 4)
    ], 2));
  }
}), ar = (e) => typeof e == "object", Rn = (e, a) => a, lr = (e) => Array.isArray(e) && e.length === 2, rr = (e) => Array.isArray(e), sr = (e) => typeof e == "object", bt = (e) => Array.isArray(e), _e = (e) => Array.isArray(e), It = (e) => Array.isArray(e) && e.length === 2, or = (e, a) => a ? Array.isArray(e) : It(e), ur = (e) => Array.isArray(e), ir = (e) => typeof e == "string" || typeof e == "object" || typeof e == "number", Vn = (e) => typeof e == "string", dr = { class: "dp__selection_preview" }, cr = { class: "dp__action_buttons" }, fr = ["onKeydown"], mr = /* @__PURE__ */ Be({
  __name: "ActionRow",
  props: {
    ...ta,
    ...hn,
    ...ea,
    ...gn,
    ...la,
    inline: { type: Boolean, default: !1 },
    timePicker: { type: Boolean, default: !1 },
    calendarWidth: { type: Number, default: 0 },
    menuMount: { type: Boolean, default: !1 },
    enableTimePicker: { type: Boolean, default: !0 }
  },
  emits: ["closePicker", "selectDate", "invalid-select"],
  setup(e, { emit: a }) {
    const n = e, { buildMatrix: t } = qe(), c = ve(Jn), m = ve(Xe), v = V(null), y = V(null);
    Oe(() => {
      m != null && m.value && t([we(v), we(y)], "actionRow");
    });
    const h = C(() => n.range && !n.partialRange && n.internalModelValue ? n.internalModelValue.length === 2 : !0), M = C(() => ({
      dp__action: !0,
      dp__select: !0,
      dp__action_disabled: !R.value || !B.value || !h.value
    })), R = C(() => n.enableTimePicker ? on(n.internalModelValue, n.maxTime, n.minTime, n.maxDate, n.minDate) : !0), B = C(() => n.monthPicker ? Al(n.internalModelValue, n.minDate, n.maxDate) : !0), Y = (T) => mt(T, n.previewFormat, c == null ? void 0 : c.value), O = C(() => !n.internalModelValue || !n.menuMount ? "" : typeof n.previewFormat == "string" ? bt(n.internalModelValue) ? n.internalModelValue.length === 2 && n.internalModelValue[1] ? n.multiCalendars > 0 ? `${Y(n.internalModelValue[0])} - ${Y(
      n.internalModelValue[1]
    )}` : [Y(n.internalModelValue[0]), Y(n.internalModelValue[1])] : n.multiDates ? n.internalModelValue.map((T) => `${Y(T)}`) : n.modelAuto ? `${Y(n.internalModelValue[0])}` : `${Y(n.internalModelValue[0])} -` : mt(n.internalModelValue, n.previewFormat, c == null ? void 0 : c.value) : n.timePicker ? n.previewFormat(sn(n.internalModelValue)) : n.monthPicker ? n.previewFormat(Nt(n.internalModelValue)) : n.previewFormat(n.internalModelValue)), L = () => {
      R.value && B.value && h.value ? a("selectDate") : a("invalid-select");
    };
    return (T, te) => (g(), S("div", {
      class: "dp__action_row",
      style: ft(e.calendarWidth ? { width: `${e.calendarWidth}px` } : {})
    }, [
      W("div", dr, [
        T.$slots["action-preview"] ? K(T.$slots, "action-preview", {
          key: 0,
          value: T.internalModelValue
        }) : b("", !0),
        T.$slots["action-preview"] ? b("", !0) : (g(), S(se, { key: 1 }, [
          Array.isArray(u(O)) ? b("", !0) : (g(), S(se, { key: 0 }, [
            Fe(ge(u(O)), 1)
          ], 64)),
          Array.isArray(u(O)) ? (g(!0), S(se, { key: 1 }, ye(u(O), (l, o) => (g(), S("div", { key: o }, ge(l), 1))), 128)) : b("", !0)
        ], 64))
      ]),
      W("div", cr, [
        T.$slots["action-select"] ? K(T.$slots, "action-select", {
          key: 0,
          value: T.internalModelValue
        }) : b("", !0),
        T.$slots["action-select"] ? b("", !0) : (g(), S(se, { key: 1 }, [
          e.inline ? b("", !0) : (g(), S("span", {
            key: 0,
            class: "dp__action dp__cancel",
            ref_key: "cancelButtonRef",
            ref: v,
            tabindex: "0",
            onClick: te[0] || (te[0] = (l) => T.$emit("closePicker")),
            onKeydown: te[1] || (te[1] = de((l) => T.$emit("closePicker"), ["enter"]))
          }, ge(T.cancelText), 545)),
          W("span", {
            class: fe(u(M)),
            tabindex: "0",
            onKeydown: de(L, ["enter"]),
            onClick: L,
            ref_key: "selectButtonRef",
            ref: y
          }, ge(T.selectText), 43, fr)
        ], 64))
      ])
    ], 4));
  }
}), vr = { class: "dp__selection_grid_header" }, yr = ["aria-selected", "aria-disabled", "onClick", "onKeydown", "onMouseover"], pr = ["aria-label", "onKeydown"], Dt = /* @__PURE__ */ Be({
  __name: "SelectionGrid",
  props: {
    items: { type: Array, default: () => [] },
    modelValue: { type: [String, Number], default: null },
    multiModelValue: { type: Array, default: () => [] },
    disabledValues: { type: Array, default: () => [] },
    minValue: { type: [Number, String], default: null },
    maxValue: { type: [Number, String], default: null },
    year: { type: Number, default: 0 },
    skipActive: { type: Boolean, default: !1 },
    headerRefs: { type: Array, default: () => [] },
    skipButtonRef: { type: Boolean, default: !1 },
    monthPicker: { type: Boolean, default: !1 },
    yearPicker: { type: Boolean, default: !1 }
  },
  emits: ["update:modelValue", "selected", "toggle", "reset-flow"],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = V(!1), m = V(null), v = V(null), y = V([]), h = ve(vn, !1), M = ve(qn, V(!1)), R = ve(ze), B = ve(Xe), Y = V(), O = V(), { setSelectionGrid: L, buildMultiLevelMatrix: T, setMonthPicker: te } = qe();
    ba(() => {
      m.value = null;
    }), Oe(() => {
      St().then(() => Q()), o(), l(!0);
    }), un(() => l(!1));
    const l = (s) => {
      var w;
      B != null && B.value && ((w = t.headerRefs) != null && w.length ? te(s) : L(s));
    }, o = () => {
      const s = we(v);
      s && (M.value || s.focus({ preventScroll: !0 }), c.value = s.clientHeight < s.scrollHeight);
    }, F = C(
      () => ({
        dp__overlay: !0
      })
    ), J = C(() => ({
      dp__overlay_col: !0
    })), _ = C(() => t.items.map((s) => s.filter((w) => w).map((w) => {
      var I, re, p;
      const E = t.disabledValues.some((P) => P === w.value) || Z(w.value), ee = (I = t.multiModelValue) != null && I.length ? (re = t.multiModelValue) == null ? void 0 : re.some(
        (P) => ue(
          P,
          lt(
            t.monthPicker ? Ot(new Date(), w.value) : new Date(),
            t.monthPicker ? t.year : w.value
          )
        )
      ) : t.skipActive ? !1 : w.value === t.modelValue;
      return {
        ...w,
        className: {
          dp__overlay_cell_active: ee,
          dp__overlay_cell: !ee,
          dp__overlay_cell_disabled: E,
          dp__overlay_cell_active_disabled: E && ee,
          dp__overlay_cell_pad: !0,
          dp__cell_in_between: (p = t.multiModelValue) != null && p.length ? D(w.value) : !1
        }
      };
    }))), U = C(
      () => ({
        dp__button: !0,
        dp__overlay_action: !0,
        dp__over_action_scroll: c.value,
        dp__button_bottom: h
      })
    ), z = C(() => {
      var s, w;
      return {
        dp__overlay_container: !0,
        dp__container_flex: ((s = t.items) == null ? void 0 : s.length) <= 6,
        dp__container_block: ((w = t.items) == null ? void 0 : w.length) > 6
      };
    }), Z = (s) => {
      const w = t.maxValue || t.maxValue === 0, E = t.minValue || t.minValue === 0;
      return !w && !E ? !1 : w && E ? +s > +t.maxValue || +s < +t.minValue : w ? +s > +t.maxValue : E ? +s < +t.minValue : !1;
    }, Q = () => {
      const s = we(m);
      if (s) {
        const w = we(v);
        w && (w.scrollTop = s.offsetTop - w.offsetTop - (w.getBoundingClientRect().height / 2 - s.getBoundingClientRect().height));
      }
    }, j = (s) => {
      !t.disabledValues.some((w) => w === s) && !Z(s) && (n("update:modelValue", s), n("selected"));
    }, D = (s) => {
      const w = t.monthPicker ? t.year : s;
      return jn(
        t.multiModelValue,
        lt(
          t.monthPicker ? Ot(new Date(), Y.value || 0) : new Date(),
          t.monthPicker ? w : Y.value || w
        ),
        lt(t.monthPicker ? Ot(new Date(), s) : new Date(), w)
      );
    }, $ = () => {
      n("toggle"), n("reset-flow");
    }, i = (s, w, E, ee) => {
      var I, re;
      if (s && (w.value === +t.modelValue && !t.disabledValues.includes(w.value) && (m.value = s), B != null && B.value)) {
        Array.isArray(y.value[E]) ? y.value[E][ee] = s : y.value[E] = [s];
        const p = (I = t.headerRefs) != null && I.length ? [t.headerRefs].concat(y.value) : y.value.concat([t.skipButtonRef ? [] : [O.value]]);
        T(p, (re = t.headerRefs) != null && re.length ? "monthPicker" : "selectionGrid");
      }
    };
    return a({ focusGrid: o }), (s, w) => (g(), S("div", {
      ref_key: "gridWrapRef",
      ref: v,
      class: fe(u(F)),
      role: "dialog",
      tabindex: "0"
    }, [
      W("div", {
        class: fe(u(z)),
        role: "grid"
      }, [
        W("div", vr, [
          K(s.$slots, "header")
        ]),
        (g(!0), S(se, null, ye(u(_), (E, ee) => (g(), S("div", {
          class: "dp__overlay_row",
          key: u(Ll)(ee),
          role: "row"
        }, [
          (g(!0), S(se, null, ye(E, (I, re) => (g(), S("div", {
            role: "gridcell",
            class: fe(u(J)),
            key: I.value,
            "aria-selected": I.value === e.modelValue && !e.disabledValues.includes(I.value),
            "aria-disabled": I.className.dp__overlay_cell_disabled,
            ref_for: !0,
            ref: (p) => i(p, I, ee, re),
            tabindex: "0",
            onClick: (p) => j(I.value),
            onKeydown: de((p) => j(I.value), ["enter"]),
            onMouseover: (p) => Y.value = I.value
          }, [
            W("div", {
              class: fe(I.className)
            }, [
              s.$slots.item ? K(s.$slots, "item", {
                key: 0,
                item: I
              }) : b("", !0),
              s.$slots.item ? b("", !0) : (g(), S(se, { key: 1 }, [
                Fe(ge(I.text), 1)
              ], 64))
            ], 2)
          ], 42, yr))), 128))
        ]))), 128)),
        s.$slots["button-icon"] ? (g(), S("div", {
          key: 0,
          role: "button",
          "aria-label": u(R).toggleOverlay,
          class: fe(u(U)),
          tabindex: "0",
          ref_key: "toggleButton",
          ref: O,
          onClick: $,
          onKeydown: de($, ["enter"])
        }, [
          K(s.$slots, "button-icon")
        ], 42, pr)) : b("", !0)
      ], 2)
    ], 2));
  }
}), Ut = () => {
  const e = ve(Wt);
  return { transitionName: C(() => (n) => e != null && e.value ? n ? e.value.open : e.value.close : ""), showTransition: !!(e != null && e.value) };
}, hr = {
  key: 0,
  class: "dp__time_input"
}, gr = /* @__PURE__ */ Fe(" : "), wr = ["aria-label", "onKeydown", "onClick"], kr = ["aria-label", "onKeydown", "onClick"], br = ["aria-label", "onKeydown", "onClick"], $r = { key: 0 }, Dr = ["aria-label", "onKeydown"], Mr = /* @__PURE__ */ Be({
  __name: "TimeInput",
  props: {
    ...Zn,
    hours: { type: Number, default: 0 },
    minutes: { type: Number, default: 0 },
    seconds: { type: Number, default: 0 },
    filters: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: !1 },
    closeTimePickerBtn: { type: Object, default: null },
    order: { type: Number, default: 0 }
  },
  emits: [
    "setHours",
    "setMinutes",
    "update:hours",
    "update:minutes",
    "update:seconds",
    "reset-flow",
    "mounted",
    "overlay-closed"
  ],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = Kt({
      hours: !1,
      minutes: !1,
      seconds: !1
    }), m = V("AM"), v = V(null), y = ve(ze), h = ve(Xe), M = V([]), { transitionName: R, showTransition: B } = Ut(), { setTimePickerElements: Y, setTimePickerBackRef: O } = qe();
    Oe(() => {
      n("mounted");
    });
    const L = C(
      () => ({
        dp__time_col: !0,
        dp__time_col_reg: !t.enableSeconds && t.is24,
        dp__time_col_reg_with_button: !t.enableSeconds && !t.is24,
        dp__time_col_sec: t.enableSeconds && t.is24,
        dp__time_col_sec_with_button: t.enableSeconds && !t.is24
      })
    ), T = C(() => {
      const D = [{ type: "hours" }, "separator", { type: "minutes" }];
      return t.enableSeconds ? D.concat(["separator", { type: "seconds" }]) : D;
    }), te = C(() => T.value.filter((D) => typeof D != "string")), l = C(() => (D) => {
      if (D === "hours") {
        const $ = U(t.hours);
        return { text: $ < 10 ? `0${$}` : `${$}`, value: $ };
      }
      return { text: t[D] < 10 ? `0${t[D]}` : `${t[D]}`, value: t[D] };
    }), o = (D) => {
      const $ = D === "hours" ? t.is24 ? 24 : 12 : 60, i = +t[`${D}GridIncrement`], s = [];
      for (let w = 0; w < $; w += i)
        s.push({ value: w, text: w < 10 ? `0${w}` : `${w}` });
      return Yl(s);
    }, F = (D) => t[`no${D[0].toUpperCase() + D.slice(1)}Overlay`], J = (D) => {
      F(D) || (c[D] = !c[D], c[D] || n("overlay-closed"));
    }, _ = (D, $ = !0) => {
      const i = D === "hours" ? Re : D === "minutes" ? Ve : Ge, s = $ ? Ml : Sl;
      n(`update:${D}`, i(s({ [D]: +t[D] }, { [D]: +t[`${D}Increment`] })));
    }, U = (D) => t.is24 ? D : (D >= 12 ? m.value = "PM" : m.value = "AM", Hl(D)), z = () => {
      m.value === "PM" ? (m.value = "AM", n("update:hours", t.hours - 12)) : (m.value = "PM", n("update:hours", t.hours + 12));
    }, Z = (D) => {
      c[D] = !0;
    }, Q = (D, $, i) => {
      if (D && (h == null ? void 0 : h.value)) {
        Array.isArray(M.value[$]) ? M.value[$][i] = D : M.value[$] = [D];
        const s = M.value.reduce(
          (w, E) => E.map((ee, I) => [...w[I] || [], E[I]]),
          []
        );
        O(t.closeTimePickerBtn), v.value && (s[1] = s[1].concat(v.value)), Y(s, t.order);
      }
    }, j = (D, $) => D === "hours" && !t.is24 ? n(`update:${D}`, m.value === "PM" ? $ + 12 : $) : n(`update:${D}`, $);
    return a({ openChildCmp: Z }), (D, $) => e.disabled ? b("", !0) : (g(), S("div", hr, [
      (g(!0), S(se, null, ye(u(T), (i, s) => (g(), S("div", {
        key: s,
        class: fe(u(L))
      }, [
        i === "separator" ? (g(), S(se, { key: 0 }, [
          gr
        ], 64)) : (g(), S(se, { key: 1 }, [
          W("div", {
            class: "dp__inc_dec_button",
            role: "button",
            "aria-label": u(y).incrementValue(i.type),
            tabindex: "0",
            onKeydown: de((w) => _(i.type), ["enter"]),
            onClick: (w) => _(i.type),
            ref_for: !0,
            ref: (w) => Q(w, s, 0)
          }, [
            D.$slots["arrow-up"] ? K(D.$slots, "arrow-up", { key: 0 }) : b("", !0),
            D.$slots["arrow-up"] ? b("", !0) : (g(), le(u(Un), { key: 1 }))
          ], 40, wr),
          W("div", {
            role: "button",
            "aria-label": u(y).openTpOverlay(i.type),
            class: fe(F(i.type) ? "" : "dp__time_display"),
            tabindex: "0",
            onKeydown: de((w) => J(i.type), ["enter"]),
            onClick: (w) => J(i.type),
            ref_for: !0,
            ref: (w) => Q(w, s, 1)
          }, [
            D.$slots[i.type] ? K(D.$slots, i.type, {
              key: 0,
              text: u(l)(i.type).text,
              value: u(l)(i.type).value
            }) : b("", !0),
            D.$slots[i.type] ? b("", !0) : (g(), S(se, { key: 1 }, [
              Fe(ge(u(l)(i.type).text), 1)
            ], 64))
          ], 42, kr),
          W("div", {
            class: "dp__inc_dec_button",
            role: "button",
            "aria-label": u(y).decrementValue(i.type),
            tabindex: "0",
            onKeydown: de((w) => _(i.type, !1), ["enter"]),
            onClick: (w) => _(i.type, !1),
            ref_for: !0,
            ref: (w) => Q(w, s, 2)
          }, [
            D.$slots["arrow-down"] ? K(D.$slots, "arrow-down", { key: 0 }) : b("", !0),
            D.$slots["arrow-down"] ? b("", !0) : (g(), le(u(Ln), { key: 1 }))
          ], 40, br)
        ], 64))
      ], 2))), 128)),
      D.is24 ? b("", !0) : (g(), S("div", $r, [
        D.$slots["am-pm-button"] ? K(D.$slots, "am-pm-button", {
          key: 0,
          toggle: z,
          value: m.value
        }) : b("", !0),
        D.$slots["am-pm-button"] ? b("", !0) : (g(), S("button", {
          key: 1,
          ref_key: "amPmButton",
          ref: v,
          type: "button",
          class: "dp__pm_am_button",
          role: "button",
          "aria-label": u(y).amPmButton,
          tabindex: "0",
          onClick: z,
          onKeydown: de(Ue(z, ["prevent"]), ["enter"])
        }, ge(m.value), 41, Dr))
      ])),
      (g(!0), S(se, null, ye(u(te), (i, s) => (g(), le(yt, {
        key: s,
        name: u(R)(c[i.type]),
        css: u(B)
      }, {
        default: ae(() => [
          c[i.type] ? (g(), le(Dt, {
            key: 0,
            items: o(i.type),
            "disabled-values": e.filters.times[i.type],
            "onUpdate:modelValue": (w) => j(i.type, w),
            onSelected: (w) => J(i.type),
            onToggle: (w) => J(i.type),
            onResetFlow: $[0] || ($[0] = (w) => D.$emit("reset-flow"))
          }, Pe({
            "button-icon": ae(() => [
              D.$slots["clock-icon"] ? K(D.$slots, "clock-icon", { key: 0 }) : b("", !0),
              D.$slots["clock-icon"] ? b("", !0) : (g(), le(u(Wn), { key: 1 }))
            ]),
            _: 2
          }, [
            D.$slots[`${i.type}-overlay`] ? {
              name: "item",
              fn: ae(({ item: w }) => [
                K(D.$slots, `${i.type}-overlay`, {
                  text: w.text,
                  value: w.value
                })
              ])
            } : void 0
          ]), 1032, ["items", "disabled-values", "onUpdate:modelValue", "onSelected", "onToggle"])) : b("", !0)
        ]),
        _: 2
      }, 1032, ["name", "css"]))), 128))
    ]));
  }
}), kt = [
  { name: "clock-icon", use: ["time", "calendar"] },
  { name: "arrow-left", use: ["month-year", "calendar"] },
  { name: "arrow-right", use: ["month-year", "calendar"] },
  { name: "arrow-up", use: ["time", "calendar"] },
  { name: "arrow-down", use: ["time", "calendar"] },
  { name: "calendar-icon", use: ["month-year", "time", "calendar"] },
  { name: "day", use: ["calendar"] },
  { name: "month-overlay", use: ["calendar", "month-year"] },
  { name: "year-overlay", use: ["calendar", "month-year"] },
  { name: "hours-overlay", use: ["calendar", "time"] },
  { name: "minutes-overlay", use: ["calendar", "time"] },
  { name: "seconds-overlay", use: ["calendar", "time"] },
  { name: "hours", use: ["calendar", "time"] },
  { name: "minutes", use: ["calendar", "time"] },
  { name: "month", use: ["calendar", "month-year"] },
  { name: "year", use: ["calendar", "month-year"] },
  { name: "action-select", use: ["action"] },
  { name: "action-preview", use: ["action"] },
  { name: "calendar-header", use: ["calendar"] },
  { name: "marker-tooltip", use: ["calendar"] },
  { name: "now-button", use: [] },
  { name: "time-picker-overlay", use: ["calendar", "time"] },
  { name: "am-pm-button", use: ["calendar", "time"] }
], Sr = [{ name: "trigger" }, { name: "input-icon" }, { name: "clear-icon" }, { name: "dp-input" }], Ar = {
  all: () => kt,
  monthYear: () => kt.filter((e) => e.use.includes("month-year")),
  input: () => Sr,
  timePicker: () => kt.filter((e) => e.use.includes("time")),
  action: () => kt.filter((e) => e.use.includes("action")),
  calendar: () => kt.filter((e) => e.use.includes("calendar"))
}, at = (e, a) => {
  const n = [];
  return Ar[a]().forEach((t) => {
    e[t.name] && n.push(t.name);
  }), n;
}, Pr = ["aria-label"], Cr = { class: "dp__overlay_container dp__container_flex" }, Tr = {
  key: 1,
  class: "dp__overlay_row"
}, _r = ["aria-label"], Rr = /* @__PURE__ */ Be({
  __name: "TimePicker",
  props: {
    ...Qn,
    range: { type: Boolean, default: !1 },
    filters: { type: Object, default: () => ({}) },
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    customProps: { type: Object, default: null },
    modelAuto: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null }
  },
  emits: [
    "update:hours",
    "update:minutes",
    "update:seconds",
    "mount",
    "reset-flow",
    "overlay-closed"
  ],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = dn(), m = V(null), v = V(null), y = ve(vn, !1), h = V([]), M = V(null), R = ve(ze), B = ve(Xe), { transitionName: Y, showTransition: O } = Ut(), { buildMatrix: L, setTimePicker: T } = qe();
    Oe(() => {
      n("mount"), !t.timePicker && (B == null ? void 0 : B.value) ? L([we(m.value)], "time") : T(!0, t.timePicker);
    });
    const te = C(() => t.range && t.modelAuto ? sa(t.internalModelValue) : !0), l = V(!1), o = ($) => ({
      hours: Array.isArray(t.hours) ? t.hours[$] : t.hours,
      minutes: Array.isArray(t.minutes) ? t.minutes[$] : t.minutes,
      seconds: Array.isArray(t.seconds) ? t.seconds[$] : t.seconds
    }), F = C(() => {
      const $ = [];
      if (t.range)
        for (let i = 0; i < 2; i++)
          $.push(o(i));
      else
        $.push(o(0));
      return $;
    }), J = ($, i = !1, s = "") => {
      i || n("reset-flow"), l.value = $, B != null && B.value && (T($), $ || n("overlay-closed")), St(() => {
        s !== "" && h.value[0] && h.value[0].openChildCmp(s);
      });
    }, _ = C(() => ({
      dp__button: !0,
      dp__button_bottom: y
    })), U = at(c, "timePicker"), z = ($, i, s) => t.range ? i === 0 ? [$, F.value[1][s]] : [F.value[0][s], $] : $, Z = ($) => {
      n("update:hours", $);
    }, Q = ($) => {
      n("update:minutes", $);
    }, j = ($) => {
      n("update:seconds", $);
    }, D = () => {
      M.value && (B == null ? void 0 : B.value) && M.value.focus({ preventScroll: !0 });
    };
    return a({ toggleTimePicker: J }), ($, i) => (g(), S("div", null, [
      $.timePicker ? b("", !0) : (g(), S("div", {
        key: 0,
        class: fe(u(_)),
        role: "button",
        "aria-label": u(R).openTimePicker,
        tabindex: "0",
        ref_key: "openTimePickerBtn",
        ref: m,
        onKeydown: i[0] || (i[0] = de((s) => J(!0), ["enter"])),
        onClick: i[1] || (i[1] = (s) => J(!0))
      }, [
        $.$slots["clock-icon"] ? K($.$slots, "clock-icon", { key: 0 }) : b("", !0),
        $.$slots["clock-icon"] ? b("", !0) : (g(), le(u(Wn), { key: 1 }))
      ], 42, Pr)),
      je(yt, {
        name: u(Y)(l.value),
        css: u(O)
      }, {
        default: ae(() => [
          l.value || $.timePicker ? (g(), S("div", {
            key: 0,
            class: "dp__overlay",
            ref_key: "overlayRef",
            ref: M,
            tabindex: "0"
          }, [
            W("div", Cr, [
              $.$slots["time-picker-overlay"] ? K($.$slots, "time-picker-overlay", {
                key: 0,
                range: e.range,
                hours: e.hours,
                minutes: e.minutes,
                seconds: e.seconds,
                setHours: Z,
                setMinutes: Q,
                setSeconds: j
              }) : b("", !0),
              $.$slots["time-picker-overlay"] ? b("", !0) : (g(), S("div", Tr, [
                (g(!0), S(se, null, ye(u(F), (s, w) => $a((g(), le(Mr, Ae({
                  key: w,
                  disabled: w === 0 ? $.fixedStart : $.fixedEnd,
                  hours: s.hours,
                  minutes: s.minutes,
                  seconds: s.seconds,
                  filters: e.filters,
                  ref_for: !0,
                  ref_key: "timeInputRefs",
                  ref: h
                }, {
                  is24: $.is24,
                  hoursGridIncrement: $.hoursGridIncrement,
                  minutesGridIncrement: $.minutesGridIncrement,
                  secondsGridIncrement: $.secondsGridIncrement,
                  hoursIncrement: $.hoursIncrement,
                  minutesIncrement: $.minutesIncrement,
                  secondsIncrement: $.secondsIncrement,
                  filters: e.filters,
                  noHoursOverlay: $.noHoursOverlay,
                  noMinutesOverlay: $.noMinutesOverlay,
                  noSecondsOverlay: $.noSecondsOverlay,
                  enableSeconds: $.enableSeconds,
                  closeTimePickerBtn: v.value,
                  order: w
                }, {
                  "onUpdate:hours": (E) => Z(z(E, w, "hours")),
                  "onUpdate:minutes": (E) => Q(z(E, w, "minutes")),
                  "onUpdate:seconds": (E) => j(z(E, w, "seconds")),
                  onMounted: D,
                  onOverlayClosed: D
                }), Pe({ _: 2 }, [
                  ye(u(U), (E, ee) => ({
                    name: E,
                    fn: ae((I) => [
                      K($.$slots, E, Le(nt(I)))
                    ])
                  }))
                ]), 1040, ["disabled", "hours", "minutes", "seconds", "filters", "onUpdate:hours", "onUpdate:minutes", "onUpdate:seconds"])), [
                  [Da, w === 0 ? !0 : u(te)]
                ])), 128))
              ])),
              $.timePicker ? b("", !0) : (g(), S("div", {
                key: 2,
                ref_key: "closeTimePickerBtn",
                ref: v,
                class: fe(u(_)),
                role: "button",
                "aria-label": u(R).closeTimePicker,
                tabindex: "0",
                onKeydown: i[2] || (i[2] = de((s) => J(!1), ["enter"])),
                onClick: i[3] || (i[3] = (s) => J(!1))
              }, [
                $.$slots["calendar-icon"] ? K($.$slots, "calendar-icon", { key: 0 }) : b("", !0),
                $.$slots["calendar-icon"] ? b("", !0) : (g(), le(u(Ht), { key: 1 }))
              ], 42, _r))
            ])
          ], 512)) : b("", !0)
        ]),
        _: 3
      }, 8, ["name", "css"])
    ]));
  }
}), Vr = ["aria-label"], nn = /* @__PURE__ */ Be({
  __name: "ActionIcon",
  props: { ariaLabel: { type: String, default: "" } },
  emits: ["activate", "setRef"],
  setup(e, { emit: a }) {
    const n = V(null);
    return Oe(() => a("setRef", n)), (t, c) => (g(), S("div", {
      class: "dp__month_year_col_nav",
      onClick: c[0] || (c[0] = (m) => t.$emit("activate")),
      onKeydown: c[1] || (c[1] = de((m) => t.$emit("activate"), ["enter"])),
      tabindex: "0",
      ref_key: "elRef",
      ref: n
    }, [
      W("div", {
        class: "dp__inner_nav",
        role: "button",
        "aria-label": e.ariaLabel
      }, [
        K(t.$slots, "default")
      ], 8, Vr)
    ], 544));
  }
}), Br = ["aria-label"], Bn = /* @__PURE__ */ Be({
  __name: "RegularPicker",
  props: {
    ariaLabel: { type: String, default: "" },
    showSelectionGrid: { type: Boolean, default: !1 },
    modelValue: { type: Number, default: null },
    items: { type: Array, default: () => [] },
    disabledValues: { type: Array, default: () => [] },
    minValue: { type: Number, default: null },
    maxValue: { type: Number, default: null },
    slotName: { type: String, default: "" },
    headerRefs: { type: Array, default: () => [] }
  },
  emits: ["update:model-value", "toggle", "setRef"],
  setup(e, { emit: a }) {
    const { transitionName: n, showTransition: t } = Ut(), c = V(null);
    return Oe(() => a("setRef", c)), (m, v) => (g(), S(se, null, [
      W("div", {
        class: "dp__month_year_select",
        onClick: v[0] || (v[0] = (y) => m.$emit("toggle")),
        onKeydown: v[1] || (v[1] = de((y) => m.$emit("toggle"), ["enter"])),
        role: "button",
        "aria-label": e.ariaLabel,
        tabindex: "0",
        ref_key: "elRef",
        ref: c
      }, [
        K(m.$slots, "default")
      ], 40, Br),
      je(yt, {
        name: u(n)(e.showSelectionGrid),
        css: u(t)
      }, {
        default: ae(() => [
          e.showSelectionGrid ? (g(), le(Dt, Ae({ key: 0 }, {
            modelValue: e.modelValue,
            items: e.items,
            disabledValues: e.disabledValues,
            minValue: e.minValue,
            maxValue: e.maxValue
          }, {
            "header-refs": [],
            "onUpdate:modelValue": v[2] || (v[2] = (y) => m.$emit("update:model-value", y)),
            onToggle: v[3] || (v[3] = (y) => m.$emit("toggle"))
          }), Pe({
            "button-icon": ae(() => [
              m.$slots["calendar-icon"] ? K(m.$slots, "calendar-icon", { key: 0 }) : b("", !0),
              m.$slots["calendar-icon"] ? b("", !0) : (g(), le(u(Ht), { key: 1 }))
            ]),
            _: 2
          }, [
            m.$slots[e.slotName] ? {
              name: "item",
              fn: ae(({ item: y }) => [
                K(m.$slots, e.slotName, { item: y })
              ])
            } : void 0
          ]), 1040)) : b("", !0)
        ]),
        _: 3
      }, 8, ["name", "css"])
    ], 64));
  }
}), Ft = (e, a, n) => [Ce(new Date(e), { date: 1 }), Ce(new Date(), { month: a, year: n, date: 1 })], On = (e, a, n) => ke(...Ft(e, a, n)) || ue(...Ft(e, a, n)), Nn = (e, a, n) => De(...Ft(e, a, n)) || ue(...Ft(e, a, n)), oa = (e, a, n, t, c, m) => {
  let v = !1;
  return m ? e && a ? (a && c && Nn(a, n, t) && (v = !0), e && !c && On(e, n, t) && (v = !0)) : (e && On(e, n, t) || a && Nn(a, n, t)) && (v = !0) : v = !0, v;
}, Or = (e, a) => {
  const n = (v, y) => {
    let h = v;
    return e.filters.months.includes(me(h)) ? (h = y ? vt(v, 1) : Et(v, 1), n(h, y)) : h;
  }, t = (v, y) => {
    let h = v;
    return e.filters.years.includes(ie(h)) ? (h = y ? Va(v, 1) : Ba(v, 1), t(h, y)) : h;
  }, c = (v) => {
    const y = Ce(new Date(), { month: e.month, year: e.year });
    let h = v ? vt(y, 1) : Et(y, 1), M = me(h), R = ie(h);
    e.filters.months.includes(M) && (h = n(h, v), M = me(h), R = ie(h)), e.filters.years.includes(R) && (h = t(h, v), R = ie(h)), oa(e.minDate, e.maxDate, M, R, v, e.preventMinMaxNavigation) && m(M, R);
  }, m = (v, y) => {
    a("update-month-year", { month: v, year: y });
  };
  return { handleMonthYearChange: c };
}, Nr = { class: "dp__month_year_row" }, Ir = { class: "dp__month_picker_header" }, Yr = ["aria-label"], Er = ["aria-label", "onKeydown"], Fr = ["aria-label"], Kr = /* @__PURE__ */ Be({
  __name: "MonthYearPicker",
  props: {
    ...ra,
    ...hn,
    preventMinMaxNavigation: { type: Boolean, default: !1 },
    reverseYears: { type: Boolean, default: !1 },
    years: { type: Array, default: () => [] },
    months: { type: Array, default: () => [] },
    filters: { type: Object, default: () => ({}) },
    multiCalendarsSolo: { type: Boolean, default: !1 },
    yearPicker: { type: Boolean, default: !1 }
  },
  emits: ["update-month-year", "monthYearSelect", "mount", "reset-flow", "overlay-closed"],
  setup(e, { expose: a, emit: n }) {
    const t = e, { transitionName: c, showTransition: m } = Ut(), { buildMatrix: v } = qe(), y = V(!1), h = V(!1), M = V([null, null, null, null]), R = V(null), B = V(null), Y = V(null), O = ve(ze), L = ve(Xe), { handleMonthYearChange: T } = Or(t, n);
    Oe(() => {
      n("mount");
    });
    const te = (p) => ({
      get: () => t[p],
      set: (P) => {
        const N = p === "month" ? "year" : "month";
        n("update-month-year", { [p]: P, [N]: t[N] }), n("monthYearSelect", p === "year"), p === "month" ? w(!0) : E(!0);
      }
    }), l = C(te("month")), o = C(te("year")), F = C(() => (p) => {
      const P = p === "month";
      return {
        showSelectionGrid: (P ? y : h).value,
        items: (P ? D : $).value,
        disabledValues: t.filters[P ? "months" : "years"],
        minValue: (P ? U : J).value,
        maxValue: (P ? z : _).value,
        headerRefs: P && t.monthPicker ? [R.value, B.value, Y.value] : []
      };
    }), J = C(() => t.minDate ? ie(new Date(t.minDate)) : null), _ = C(() => t.maxDate ? ie(new Date(t.maxDate)) : null), U = C(() => {
      if (t.minDate && J.value) {
        if (J.value > t.year)
          return 12;
        if (J.value === t.year)
          return me(new Date(t.minDate));
      }
      return null;
    }), z = C(() => t.maxDate && _.value ? _.value < t.year ? -1 : _.value === t.year ? me(new Date(t.maxDate)) : null : null), Z = C(() => t.range && t.internalModelValue && (t.monthPicker || t.yearPicker) ? t.internalModelValue : []), Q = (p, P = !1) => {
      const N = [];
      for (let pe = 0; pe < p.length; pe += 3) {
        const Ee = [p[pe], p[pe + 1], p[pe + 2]];
        N.push(P ? Ee.reverse() : Ee);
      }
      return P ? N.reverse() : N;
    }, j = C(() => {
      const p = t.months.find((P) => P.value === t.month);
      return p || { text: "", value: 0 };
    }), D = C(() => Q(t.months)), $ = C(() => Q(t.years, t.reverseYears)), i = C(() => t.multiCalendars ? t.multiCalendarsSolo ? !0 : t.instance === 0 : !0), s = C(() => t.multiCalendars ? t.multiCalendarsSolo ? !0 : t.instance === t.multiCalendars - 1 : !0), w = (p = !1) => {
      ee(p), y.value = !y.value, y.value || n("overlay-closed");
    }, E = (p = !1) => {
      ee(p), h.value = !h.value, h.value || n("overlay-closed");
    }, ee = (p) => {
      p || n("reset-flow");
    }, I = (p = !1) => {
      n("update-month-year", { year: p ? t.year + 1 : t.year - 1, month: t.month });
    }, re = (p, P) => {
      L != null && L.value && (M.value[P] = we(p), v(M.value, "monthYear"));
    };
    return a({
      toggleMonthPicker: w,
      toggleYearPicker: E
    }), (p, P) => (g(), S("div", Nr, [
      !p.monthPicker && !e.yearPicker ? (g(), S(se, { key: 0 }, [
        u(i) && !p.vertical ? (g(), le(nn, {
          key: 0,
          "aria-label": u(O).prevMonth,
          onActivate: P[0] || (P[0] = (N) => u(T)(!1)),
          onSetRef: P[1] || (P[1] = (N) => re(N, 0))
        }, {
          default: ae(() => [
            p.$slots["arrow-left"] ? K(p.$slots, "arrow-left", { key: 0 }) : b("", !0),
            p.$slots["arrow-left"] ? b("", !0) : (g(), le(u(Mn), { key: 1 }))
          ]),
          _: 3
        }, 8, ["aria-label"])) : b("", !0),
        je(Bn, Ae({
          "aria-label": u(O).openMonthsOverlay,
          "slot-name": "month-overlay",
          modelValue: u(l),
          "onUpdate:modelValue": P[2] || (P[2] = (N) => tt(l) ? l.value = N : null)
        }, u(F)("month"), {
          onToggle: w,
          onSetRef: P[3] || (P[3] = (N) => re(N, 1))
        }), Pe({
          default: ae(() => [
            p.$slots.month ? K(p.$slots, "month", Le(Ae({ key: 0 }, u(j)))) : b("", !0),
            p.$slots.month ? b("", !0) : (g(), S(se, { key: 1 }, [
              Fe(ge(u(j).text), 1)
            ], 64))
          ]),
          _: 2
        }, [
          p.$slots["calendar-icon"] ? {
            name: "calendar-icon",
            fn: ae(() => [
              K(p.$slots, "calendar-icon")
            ])
          } : void 0,
          p.$slots["month-overlay"] ? {
            name: "month-overlay",
            fn: ae(({ item: N }) => [
              K(p.$slots, "month-overlay", {
                text: N.text,
                value: N.value
              })
            ])
          } : void 0
        ]), 1040, ["aria-label", "modelValue"]),
        je(Bn, Ae({
          "aria-label": u(O).openYearsOverlay,
          "slot-name": "year-overlay",
          modelValue: u(o),
          "onUpdate:modelValue": P[4] || (P[4] = (N) => tt(o) ? o.value = N : null)
        }, u(F)("year"), {
          onToggle: E,
          onSetRef: P[5] || (P[5] = (N) => re(N, 2))
        }), Pe({
          default: ae(() => [
            p.$slots.year ? K(p.$slots, "year", {
              key: 0,
              year: p.year
            }) : b("", !0),
            p.$slots.year ? b("", !0) : (g(), S(se, { key: 1 }, [
              Fe(ge(p.year), 1)
            ], 64))
          ]),
          _: 2
        }, [
          p.$slots["calendar-icon"] ? {
            name: "calendar-icon",
            fn: ae(() => [
              K(p.$slots, "calendar-icon")
            ])
          } : void 0,
          p.$slots["year-overlay"] ? {
            name: "year-overlay",
            fn: ae(({ item: N }) => [
              K(p.$slots, "year-overlay", {
                text: N.text,
                value: N.value
              })
            ])
          } : void 0
        ]), 1040, ["aria-label", "modelValue"]),
        u(i) && p.vertical ? (g(), le(nn, {
          key: 1,
          "aria-label": u(O).prevMonth,
          onActivate: P[6] || (P[6] = (N) => u(T)(!1))
        }, {
          default: ae(() => [
            p.$slots["arrow-up"] ? K(p.$slots, "arrow-up", { key: 0 }) : b("", !0),
            p.$slots["arrow-up"] ? b("", !0) : (g(), le(u(Un), { key: 1 }))
          ]),
          _: 3
        }, 8, ["aria-label"])) : b("", !0),
        u(s) ? (g(), le(nn, {
          key: 2,
          "arial-label": u(O).nextMonth,
          onActivate: P[7] || (P[7] = (N) => u(T)(!0)),
          ref: "rightIcon",
          onSetRef: P[8] || (P[8] = (N) => re(N, 3))
        }, {
          default: ae(() => [
            p.$slots[p.vertical ? "arrow-down" : "arrow-right"] ? K(p.$slots, p.vertical ? "arrow-down" : "arrow-right", { key: 0 }) : b("", !0),
            p.$slots[p.vertical ? "arrow-down" : "arrow-right"] ? b("", !0) : (g(), le(Vt(p.vertical ? u(Ln) : u(Sn)), { key: 1 }))
          ]),
          _: 3
        }, 8, ["arial-label"])) : b("", !0)
      ], 64)) : b("", !0),
      p.monthPicker ? (g(), le(Dt, Ae({ key: 1 }, u(F)("month"), {
        "skip-active": t.range,
        year: p.year,
        "multi-model-value": u(Z),
        "month-picker": "",
        modelValue: u(l),
        "onUpdate:modelValue": P[15] || (P[15] = (N) => tt(l) ? l.value = N : null),
        onToggle: w,
        onSelected: P[16] || (P[16] = (N) => p.$emit("overlay-closed"))
      }), Pe({
        header: ae(() => [
          W("div", Ir, [
            W("div", {
              class: "dp__month_year_col_nav",
              tabindex: "0",
              ref_key: "mpPrevIconRef",
              ref: R,
              onClick: P[9] || (P[9] = (N) => I(!1)),
              onKeydown: P[10] || (P[10] = de((N) => I(!1), ["enter"]))
            }, [
              W("div", {
                class: "dp__inner_nav",
                role: "button",
                "aria-label": u(O).prevMonth
              }, [
                p.$slots["arrow-left"] ? K(p.$slots, "arrow-left", { key: 0 }) : b("", !0),
                p.$slots["arrow-left"] ? b("", !0) : (g(), le(u(Mn), { key: 1 }))
              ], 8, Yr)
            ], 544),
            W("div", {
              class: "dp__pointer",
              role: "button",
              ref_key: "mpYearButtonRef",
              ref: B,
              "aria-label": u(O).openYearsOverlay,
              tabindex: "0",
              onClick: E,
              onKeydown: de(E, ["enter"])
            }, [
              p.$slots.year ? K(p.$slots, "year", {
                key: 0,
                year: p.year
              }) : b("", !0),
              p.$slots.year ? b("", !0) : (g(), S(se, { key: 1 }, [
                Fe(ge(p.year), 1)
              ], 64))
            ], 40, Er),
            W("div", {
              class: "dp__month_year_col_nav",
              tabindex: "0",
              ref_key: "mpNextIconRef",
              ref: Y,
              onClick: P[11] || (P[11] = (N) => I(!0)),
              onKeydown: P[12] || (P[12] = de((N) => I(!0), ["enter"]))
            }, [
              W("div", {
                class: "dp__inner_nav",
                role: "button",
                "aria-label": u(O).nextMonth
              }, [
                p.$slots["arrow-right"] ? K(p.$slots, "arrow-right", { key: 0 }) : b("", !0),
                p.$slots["arrow-right"] ? b("", !0) : (g(), le(u(Sn), { key: 1 }))
              ], 8, Fr)
            ], 544)
          ]),
          je(yt, {
            name: u(c)(h.value),
            css: u(m)
          }, {
            default: ae(() => [
              h.value ? (g(), le(Dt, Ae({ key: 0 }, u(F)("year"), {
                modelValue: u(o),
                "onUpdate:modelValue": P[13] || (P[13] = (N) => tt(o) ? o.value = N : null),
                onToggle: E,
                onSelected: P[14] || (P[14] = (N) => p.$emit("overlay-closed"))
              }), Pe({
                "button-icon": ae(() => [
                  p.$slots["calendar-icon"] ? K(p.$slots, "calendar-icon", { key: 0 }) : b("", !0),
                  p.$slots["calendar-icon"] ? b("", !0) : (g(), le(u(Ht), { key: 1 }))
                ]),
                _: 2
              }, [
                p.$slots["year-overlay"] ? {
                  name: "item",
                  fn: ae(({ item: N }) => [
                    K(p.$slots, "year-overlay", {
                      text: N.text,
                      value: N.value
                    })
                  ])
                } : void 0
              ]), 1040, ["modelValue"])) : b("", !0)
            ]),
            _: 3
          }, 8, ["name", "css"])
        ]),
        _: 2
      }, [
        p.$slots["month-overlay"] ? {
          name: "item",
          fn: ae(({ item: N }) => [
            K(p.$slots, "month-overlay", {
              text: N.text,
              value: N.value
            })
          ])
        } : void 0
      ]), 1040, ["skip-active", "year", "multi-model-value", "modelValue"])) : b("", !0),
      e.yearPicker ? (g(), le(Dt, Ae({ key: 2 }, u(F)("year"), {
        modelValue: u(o),
        "onUpdate:modelValue": P[17] || (P[17] = (N) => tt(o) ? o.value = N : null),
        "multi-model-value": u(Z),
        "skip-active": t.range,
        "skip-button-ref": "",
        "year-picker": "",
        onToggle: E,
        onSelected: P[18] || (P[18] = (N) => p.$emit("overlay-closed"))
      }), Pe({ _: 2 }, [
        p.$slots["year-overlay"] ? {
          name: "item",
          fn: ae(({ item: N }) => [
            K(p.$slots, "year-overlay", {
              text: N.text,
              value: N.value
            })
          ])
        } : void 0
      ]), 1040, ["modelValue", "multi-model-value", "skip-active"])) : b("", !0)
    ]));
  }
}), Hr = (e, a, n, t) => {
  const c = V(new Date()), m = V(), v = V([{ month: me(new Date()), year: ie(new Date()) }]), y = V(
    e.range ? [Re(new Date()), Re(new Date())] : Re(new Date())
  ), h = V(
    e.range ? [Ve(new Date()), Ve(new Date())] : Ve(new Date())
  ), M = V(e.range ? [0, 0] : 0);
  Mt(
    v,
    () => {
      setTimeout(() => {
        e.openOnTop && a("dpOpen");
      }, 0);
    },
    { deep: !0 }
  ), Oe(() => {
    Q(!0), l.value || (e.startDate && (v.value[0].month = me(new Date(e.startDate)), v.value[0].year = ie(new Date(e.startDate)), e.multiCalendars && N(0)), e.startTime && te());
  });
  const R = C(
    () => (r) => v.value[r] ? v.value[r].month : 0
  ), B = C(
    () => (r) => v.value[r] ? v.value[r].year : 0
  ), Y = (r, k, A) => {
    v.value[r].month = k, v.value[r].year = A;
  }, O = (r, k) => v.value[r].month = k, L = (r, k) => v.value[r].year = k, T = (r = !0) => e.enableSeconds ? Array.isArray(M.value) ? r ? M.value[0] : M.value[1] : M.value : 0, te = () => {
    e.startTime && (ur(e.startTime) ? (y.value = [+e.startTime[0].hours, +e.startTime[1].hours], h.value = [+e.startTime[0].minutes, +e.startTime[1].minutes], e.enableSeconds && (M.value = [+e.startTime[0].seconds, +e.startTime[1].seconds])) : (y.value = +e.startTime.hours, h.value = +e.startTime.minutes, e.enableSeconds && (M.value = +e.startTime.seconds)));
  }, l = C({
    get: () => e.internalModelValue,
    set: (r) => {
      !e.readonly && !e.disabled && a("update:internalModelValue", r);
    }
  });
  Mt(l, () => Q());
  const o = (r) => {
    const { validate: k } = Xn(
      e.minDate,
      e.maxDate,
      e.disabledDates,
      e.allowedDates,
      e.filters,
      e.disabledWeekDays,
      e.yearRange
    );
    return !k(r);
  }, F = (r) => !l.value || e.hideOffsetDates && !r.current ? !1 : e.range ? e.modelAuto && Array.isArray(l.value) ? ue(r.value, l.value[0] ? l.value[0] : c.value) : !1 : e.multiDates && Array.isArray(l.value) ? l.value.some((k) => ue(k, r.value)) : ue(r.value, l.value ? l.value : c.value), J = (r) => jn(l.value, m.value, r.value), _ = (r, k = !1) => {
    if ((!e.multiCalendars || !e.multiStatic || k) && (O(0, me(r)), L(0, ie(r))), e.multiCalendars)
      for (let A = 1; A <= e.multiCalendars; A++) {
        const X = Ce(new Date(), { month: R.value(A - 1), year: B.value(A - 1) }), Me = Kn(X, { months: 1 });
        v.value[A] = { month: me(Me), year: ie(Me) };
      }
  }, U = () => {
    if (Array.isArray(l.value) && l.value.length === 2) {
      const r = new Date(l.value[1] ? l.value[1] : vt(l.value[0], 1)), [k, A] = [me(l.value[0]), ie(l.value[0])], [X, Me] = [me(l.value[1]), ie(l.value[1])];
      (k !== X || k === X && A !== Me) && e.multiCalendarsSolo && (O(1, me(r)), L(1, ie(r)));
    }
  }, z = (r) => {
    _(r), y.value = Re(r), h.value = Ve(r), M.value = Ge(r);
  }, Z = () => Array.isArray(l.value) && l.value.length ? l.value[l.value.length - 1] : null, Q = (r = !1) => {
    if (l.value)
      if (bt(l.value)) {
        if (l.value.length === 2 && !e.multiDates)
          _(l.value[0], r), y.value = [
            Re(l.value[0]),
            l.value[1] ? Re(l.value[1]) : Re(new Date())
          ], h.value = [
            Ve(l.value[0]),
            l.value[1] ? Ve(l.value[1]) : Ve(new Date())
          ], M.value = [
            Ge(l.value[0]),
            l.value[1] ? Ge(l.value[1]) : Ge(new Date())
          ];
        else if (bt(l.value) && e.multiDates) {
          const k = l.value[l.value.length - 1];
          k && z(k);
        }
        e.multiCalendars && e.multiCalendarsSolo && U();
      } else
        z(l.value);
    else
      e.timePicker ? (te(), e.range ? _e(y.value) && _e(h.value) && (l.value = [
        Se(new Date(), y.value[0], h.value[0], T()),
        Se(new Date(), y.value[1], h.value[1], T(!1))
      ]) : l.value = Se(
        new Date(),
        y.value,
        h.value,
        T()
      )) : e.monthPicker && !e.range ? l.value = rt(new Date(), R.value(0), B.value(0)) : e.multiCalendars ? _(new Date()) : e.yearPicker && !e.range && (l.value = new Date());
  }, j = (r) => {
    const k = me(new Date(r)), A = ie(new Date(r));
    if (O(0, k), L(0, A), e.multiCalendars > 0)
      for (let X = 1; X < e.multiCalendars; X++) {
        const Me = Dl(
          Ce(new Date(r), { year: R.value(X - 1), month: B.value(X - 1) })
        );
        O(X, Me.month), L(X, Me.year);
      }
  }, D = (r) => {
    if (l.value && Array.isArray(l.value))
      if (l.value.some((k) => ue(r, k))) {
        const k = l.value.filter((A) => !ue(A, r));
        l.value = k.length ? k : null;
      } else
        (e.multiDatesLimit && +e.multiDatesLimit > l.value.length || !e.multiDatesLimit) && l.value.push(r);
    else
      l.value = [r];
  }, $ = (r) => {
    if (Array.isArray(l.value) && l.value[0]) {
      const k = Na(r, l.value[0]), A = De(l.value[0], r) ? r : l.value[0], X = De(r, l.value[0]) ? r : l.value[0], xe = Dn({ start: A, end: X }).filter((it) => o(it)).length, Te = Math.abs(k < 0 ? k + 1 : k - 1) - xe;
      if (e.minRange && e.maxRange)
        return Te >= +e.minRange && Te <= +e.maxRange;
      if (e.minRange)
        return Te >= +e.minRange;
      if (e.maxRange)
        return Te <= +e.maxRange;
    }
    return !0;
  }, i = (r) => Array.isArray(l.value) && l.value.length === 2 ? e.fixedStart && (De(r, l.value[0]) || ue(r, l.value[0])) ? [l.value[0], r] : e.fixedEnd && (ke(r, l.value[1]) || ue(r, l.value[1])) ? [r, l.value[1]] : l.value : [], s = () => {
    e.autoApply && a("autoApply");
  }, w = (r) => !Dn({ start: r[0], end: r[1] }).some((A) => o(A)), E = (r, k = !1) => {
    if (!o(r.value) && !(!r.current && e.hideOffsetDates)) {
      if (e.weekPicker)
        return l.value = Tt(new Date(r.value), +e.weekStart), s();
      if (!e.range && !_e(y.value) && !_e(h.value)) {
        const A = Se(new Date(r.value), y.value, h.value, T());
        e.multiDates ? D(A) : l.value = A, n(), s();
      } else if (_e(y.value) && _e(h.value) && !e.multiDates) {
        let A = l.value ? l.value.slice() : [];
        if (A.length === 2 && !(e.fixedStart || e.fixedEnd) && (A = []), e.autoRange) {
          const X = [new Date(r.value), $t(new Date(r.value), +e.autoRange)];
          w(X) && (k && j(r.value), A = X);
        } else
          e.fixedStart || e.fixedEnd ? A = i(new Date(r.value)) : A[0] ? $(new Date(r.value)) && (ke(new Date(r.value), new Date(A[0])) ? A.unshift(new Date(r.value)) : A[1] = new Date(r.value)) : A[0] = new Date(r.value);
        A.length && (A[0] && !A[1] ? A[0] = Se(A[0], y.value[0], h.value[0], T()) : (A[0] = Se(A[0], y.value[0], h.value[0], T()), A[1] = Se(A[1], y.value[1], h.value[1], T(!1)), n()), l.value = A, A[0] && A[1] && e.autoApply && a("autoApply"));
      }
    }
  }, ee = (r) => {
    const k = r.find((A) => A.current);
    return k ? Oa(k.value) : "";
  }, I = (r) => {
    !r.current && e.hideOffsetDates || (m.value = r.value);
  }, re = (r) => {
    if (e.autoRange || e.weekPicker) {
      if (m.value) {
        if (e.hideOffsetDates && !r.current)
          return !1;
        const k = $t(m.value, +e.autoRange), A = Tt(new Date(m.value), +e.weekStart);
        return e.weekPicker ? ue(A[1], new Date(r.value)) : ue(k, new Date(r.value));
      }
      return !1;
    }
    return !1;
  }, p = (r) => {
    if (e.autoRange || e.weekPicker) {
      if (m.value) {
        const k = $t(m.value, +e.autoRange);
        if (e.hideOffsetDates && !r.current)
          return !1;
        const A = Tt(new Date(m.value), +e.weekStart);
        return e.weekPicker ? De(r.value, A[0]) && ke(r.value, A[1]) : De(r.value, m.value) && ke(r.value, k);
      }
      return !1;
    }
    return !1;
  }, P = (r) => {
    if (e.autoRange || e.weekPicker) {
      if (m.value) {
        if (e.hideOffsetDates && !r.current)
          return !1;
        const k = Tt(new Date(m.value), +e.weekStart);
        return e.weekPicker ? ue(k[0], r.value) : ue(m.value, r.value);
      }
      return !1;
    }
    return !1;
  }, N = (r) => {
    for (let k = r - 1; k >= 0; k--) {
      const A = Et(Ce(new Date(), { month: R.value(k + 1), year: B.value(k + 1) }), 1);
      Y(k, me(A), ie(A));
    }
    for (let k = r + 1; k <= e.multiCalendars - 1; k++) {
      const A = vt(Ce(new Date(), { month: R.value(k - 1), year: B.value(k - 1) }), 1);
      Y(k, me(A), ie(A));
    }
  }, pe = (r) => rt(new Date(), R.value(r), B.value(r)), Ee = (r, k) => {
    const A = e.monthPicker ? R.value(r) !== k.month : B.value(r) !== k.year;
    if (O(r, k.month), L(r, k.year), e.multiCalendars && !e.multiCalendarsSolo && N(r), e.monthPicker || e.yearPicker)
      if (e.range) {
        if (A) {
          let X = l.value ? l.value.slice() : [];
          X.length === 2 && X[1] !== null && (X = []), X.length ? ke(pe(r), X[0]) ? X.unshift(pe(r)) : X[1] = pe(r) : X = [pe(r)], l.value = X;
        }
      } else
        l.value = pe(r);
    n(), a("updateMonthYear", { instance: r, month: k.month, year: k.year }), At(e.multiCalendarsSolo ? r : void 0);
  }, Je = (r) => Se(r, y.value, h.value, T()), Ne = (r) => {
    bt(r) && bt(l.value) && _e(y.value) && _e(h.value) ? (r[0] && l.value[0] && (l.value[0] = Se(r[0], y.value[0], h.value[0], T())), r[1] && l.value[1] && (l.value[1] = Se(
      r[1],
      y.value[1],
      h.value[1],
      T(!1)
    ))) : e.multiDates && Array.isArray(l.value) ? l.value[l.value.length - 1] = Je(r) : !e.range && !It(r) && (l.value = Je(r)), a("timeUpdate");
  }, Ke = (r, k = !0, A = !1) => {
    const X = k ? r : y.value, Me = !k && !A ? r : h.value, xe = A ? r : M.value;
    if (e.range && It(l.value) && _e(X) && _e(Me) && _e(xe) && !e.disableTimeRangeValidation) {
      const Te = (We) => Se(l.value[We], X[We], Me[We], xe[We]), it = (We) => cn(l.value[We], 0);
      if (ue(l.value[0], l.value[1]) && (fn(Te(0), it(1)) || mn(Te(1), it(0))))
        return;
    }
    if (y.value = X, h.value = Me, M.value = xe, l.value)
      if (e.multiDates) {
        const Te = Z();
        Te && Ne(Te);
      } else
        Ne(l.value);
    else
      e.timePicker && Ne(e.range ? [new Date(), new Date()] : new Date());
    n();
  }, He = () => {
    m.value = null;
  }, Ze = (r) => Rn(l.value, e.range) && l.value[0] && m.value ? r ? De(m.value, l.value[0]) : ke(m.value, l.value[0]) : !0, be = (r, k = !0) => (e.range || e.weekPicker) && It(l.value) ? e.hideOffsetDates && !r.current ? !1 : ue(new Date(r.value), l.value[k ? 0 : 1]) : e.range ? ue(
    new Date(r.value),
    l.value && Array.isArray(l.value) ? k ? l.value[0] || null : l.value[1] : null
  ) && (k ? !ke(
    m.value || null,
    Array.isArray(l.value) ? l.value[0] : null
  ) : !0) || ue(r.value, Array.isArray(l.value) ? l.value[0] : null) && Ze(k) : !1, ot = (r, k) => Array.isArray(e.internalModelValue) && e.internalModelValue.length || e.weekPicker ? !1 : !r && !F(k) && !(!k.current && e.hideOffsetDates) && (e.range ? !be(k) && !be(k, !1) : !0), pt = (r, k, A) => Array.isArray(e.internalModelValue) && e.internalModelValue[0] && e.internalModelValue.length === 1 ? r ? !1 : A ? De(e.internalModelValue[0], k.value) : ke(e.internalModelValue[0], k.value) : !1, ht = (r = !1) => {
    e.autoApply && (e.monthPicker || e.yearPicker) && St().then(() => {
      e.range ? a("autoApply", r || !l.value || l.value.length === 1) : a("autoApply", r);
    });
  }, f = (r, k) => {
    const A = Ce(new Date(), { month: R.value(k), year: B.value(k) }), X = r < 0 ? vt(A, 1) : Et(A, 1);
    oa(
      e.minDate,
      e.maxDate,
      me(X),
      ie(X),
      r < 0,
      e.preventMinMaxNavigation
    ) && (Y(k, me(X), ie(X)), e.multiCalendars && !e.multiCalendarsSolo && N(k), At());
  }, ne = (r, k) => {
    e.monthChangeOnScroll && f(e.monthChangeOnScroll !== "inverse" ? -r.deltaY : r.deltaY, k);
  }, ce = (r, k, A = !1) => {
    e.monthChangeOnArrows && e.vertical === A && ut(r, k);
  }, ut = (r, k) => {
    f(r === "right" ? -1 : 1, k);
  }, Qe = (r) => e.markers.find((k) => ue(Ie(r.value), Ie(k.date))), Lt = () => {
    e.range ? Rn(l.value, e.range) && (l.value && l.value[0] ? l.value = ke(new Date(), l.value[0]) ? [new Date(), l.value[0]] : [l.value[0], new Date()] : l.value = [new Date()]) : a("update:internalModelValue", new Date()), e.autoApply && a("selectDate");
  }, Gt = (r) => {
    r.length && r.length <= 2 && e.range && (l.value = r.map((k) => new Date(k)), e.autoApply && a("selectDate"));
  }, At = (r) => {
    r || r === 0 ? t.value[r].triggerTransition(R.value(r), B.value(r)) : t.value.forEach((k, A) => k.triggerTransition(R.value(A), B.value(A)));
  };
  return {
    today: c,
    hours: y,
    minutes: h,
    seconds: M,
    month: R,
    year: B,
    monthYearSelect: ht,
    isDisabled: o,
    updateTime: Ke,
    setHoverDate: I,
    getWeekNum: ee,
    selectDate: E,
    rangeActive: J,
    isActiveDate: F,
    updateMonthYear: Ee,
    isHoverRangeEnd: re,
    isAutoRangeInBetween: p,
    isAutoRangeStart: P,
    clearHoverDate: He,
    rangeActiveStartEnd: be,
    handleScroll: ne,
    getMarker: Qe,
    handleArrow: ce,
    handleSwipe: ut,
    selectCurrentDate: Lt,
    isHoverDate: ot,
    isHoverDateStartEnd: pt,
    presetDateRange: Gt
  };
}, Rt = Kt({
  menuFocused: !1,
  shiftKeyInMenu: !1
}), ua = () => ({
  setMenuFocused: (t) => {
    Rt.menuFocused = t;
  },
  getStore: () => Rt,
  setShiftKey: (t) => {
    Rt.shiftKeyInMenu !== t && (Rt.shiftKeyInMenu = t);
  }
}), Wr = ["id", "aria-label", "onKeydown"], Ur = {
  key: 0,
  class: "dp__preset_ranges"
}, Lr = ["onClick"], Gr = {
  key: 1,
  class: "dp__now_wrap"
}, jr = /* @__PURE__ */ Be({
  __name: "DatepickerMenu",
  props: {
    ...aa,
    ...pn,
    ...yn,
    internalModelValue: { type: [Date, Array], default: null },
    multiCalendars: { type: Number, default: 0 },
    openOnTop: { type: Boolean, default: !1 }
  },
  emits: [
    "update:internalModelValue",
    "closePicker",
    "selectDate",
    "dpOpen",
    "autoApply",
    "timeUpdate",
    "flow-step",
    "updateMonthYear",
    "invalid-select"
  ],
  setup(e, { emit: a }) {
    const n = e, t = dn(), c = V(null), m = Kt({
      timePicker: !!(!n.enableTimePicker || n.timePicker || n.monthPicker),
      monthYearInput: !!n.timePicker,
      calendar: !1
    }), v = V([]), y = V([]), h = V(null), M = V(null), R = V(0), B = V(!1), Y = V(0), O = ve(Wt), L = ve(ze), T = ve(Xe), { setMenuFocused: te, setShiftKey: l, getStore: o } = ua();
    Oe(() => {
      var G;
      B.value = !0, (G = n.presetRanges) != null && G.length || X();
      const d = we(M);
      if (d && !n.textInput && !n.inline && (te(!0), z()), d) {
        const H = (q) => {
          !n.monthYearComponent && !n.timePickerComponent && q.preventDefault(), q.stopImmediatePropagation(), q.stopPropagation();
        };
        d.addEventListener("pointerdown", H), d.addEventListener("mousedown", H);
      }
      document.addEventListener("resize", X);
    }), un(() => {
      document.removeEventListener("resize", X);
    });
    const { arrowRight: F, arrowLeft: J, arrowDown: _, arrowUp: U } = qe(), z = () => {
      const d = we(M);
      d && d.focus({ preventScroll: !0 });
    }, Z = () => {
      var d;
      ((d = n.flow) == null ? void 0 : d.length) && Y.value !== -1 && (Y.value += 1, a("flow-step", Y.value), wn());
    }, Q = () => {
      Y.value = -1;
    }, {
      updateTime: j,
      updateMonthYear: D,
      today: $,
      month: i,
      year: s,
      hours: w,
      minutes: E,
      seconds: ee,
      isDisabled: I,
      isActiveDate: re,
      selectDate: p,
      getWeekNum: P,
      setHoverDate: N,
      isHoverRangeEnd: pe,
      isAutoRangeInBetween: Ee,
      isAutoRangeStart: Je,
      rangeActive: Ne,
      clearHoverDate: Ke,
      rangeActiveStartEnd: He,
      monthYearSelect: Ze,
      handleScroll: be,
      handleArrow: ot,
      handleSwipe: pt,
      getMarker: ht,
      selectCurrentDate: f,
      isHoverDateStartEnd: ne,
      isHoverDate: ce,
      presetDateRange: ut
    } = Hr(n, a, Z, y), Qe = at(t, "calendar"), Lt = at(t, "action"), Gt = at(t, "timePicker"), At = at(t, "monthYear"), r = C(() => n.openOnTop ? "dp__arrow_bottom" : "dp__arrow_top"), k = C(() => Fl(n.yearRange)), A = C(() => Kl(n.locale, n.monthNameFormat)), X = () => {
      const d = we(c);
      d && (R.value = d.getBoundingClientRect().width);
    }, Me = C(
      () => (d) => Il(
        i.value(d),
        s.value(d),
        +n.weekStart,
        n.hideOffsetDates
      )
    ), xe = C(
      () => n.multiCalendars > 0 && n.range ? [...Array(n.multiCalendars).keys()] : [0]
    ), Te = C(
      () => (d) => d === 1
    ), it = C(() => n.monthPicker || n.timePicker || n.yearPicker), We = C(
      () => ({
        dp__flex_display: n.multiCalendars > 0
      })
    ), da = C(() => ({
      dp__instance_calendar: n.multiCalendars > 0
    })), ca = C(() => ({
      dp__menu_disabled: n.disabled,
      dp__menu_readonly: n.readonly
    })), fa = C(
      () => (d) => pa(Me, d)
    ), ma = C(() => ({
      locale: n.locale,
      weekNumName: n.weekNumName,
      weekStart: n.weekStart,
      weekNumbers: n.weekNumbers,
      customProps: n.customProps,
      calendarClassName: n.calendarClassName,
      specificMode: it.value,
      getWeekNum: P,
      multiCalendars: n.multiCalendars,
      modeHeight: n.modeHeight,
      internalModelValue: n.internalModelValue,
      noSwipe: n.noSwipe,
      vertical: n.vertical,
      dayNames: n.dayNames,
      monthChangeOnScroll: n.monthChangeOnScroll
    })), va = C(
      () => ({
        dp__menu: !0,
        dp__menu_index: !n.inline,
        dp__relative: n.inline,
        [n.menuClassName]: !!n.menuClassName
      })
    ), ya = () => n.modelAuto && Array.isArray(n.internalModelValue) ? !!n.internalModelValue[0] : !1, gt = () => n.modelAuto ? sa(n.internalModelValue) : !0, pa = (d, G) => d.value(G).map((H) => ({
      ...H,
      days: H.days.map((q) => {
        const x = I(q.value), wt = ce(x, q), et = n.range ? n.modelAuto ? ya() && re(q) : !1 : re(q), kn = n.highlight ? zn(q.value, n.highlight) && !et : !1, bn = (n.range || n.weekPicker) && (n.multiCalendars > 0 ? q.current : !0) && !x && gt() && !(!q.current && n.hideOffsetDates) && !re(q) ? Ne(q) : !1;
        return q.marker = ht(q), q.classData = {
          dp__cell_offset: !q.current,
          dp__pointer: !x && !(!q.current && n.hideOffsetDates),
          dp__active_date: et,
          dp__date_hover: wt,
          dp__date_hover_start: ne(wt, q, !0),
          dp__date_hover_end: ne(wt, q, !1),
          dp__range_between: bn && !n.weekPicker,
          dp__range_between_week: bn && n.weekPicker,
          dp__today: !n.noToday && ue(q.value, $.value) && q.current,
          dp__cell_disabled: x,
          dp__cell_auto_range: Ee(q),
          dp__cell_auto_range_start: Je(q),
          dp__cell_auto_range_end: pe(q),
          dp__range_start: n.multiCalendars > 0 ? q.current && He(q) && gt() : He(q) && gt(),
          dp__range_end: n.multiCalendars > 0 ? q.current && He(q, !1) && gt() : He(q, !1) && gt(),
          [n.calendarCellClassName]: !!n.calendarCellClassName,
          dp__cell_highlight: kn,
          dp__cell_highlight_active: kn && et
        }, q;
      })
    })), ha = (d) => {
      d.stopPropagation(), d.preventDefault(), d.stopImmediatePropagation();
    }, ga = () => {
      n.escClose && a("closePicker");
    }, wa = (d) => {
      d.stopImmediatePropagation(), d.preventDefault(), n.spaceConfirm && a("selectDate");
    }, jt = (d) => {
      var G;
      (G = n.flow) != null && G.length && (m[d] = !0, Object.keys(m).filter((H) => !m[H]).length || wn());
    }, wn = () => {
      n.flow[Y.value] === "month" && v.value[0] && v.value[0].toggleMonthPicker(!0), n.flow[Y.value] === "year" && v.value && v.value[0].toggleYearPicker(!0), n.flow[Y.value] === "calendar" && h.value && h.value.toggleTimePicker(!1, !0), n.flow[Y.value] === "time" && h.value && h.value.toggleTimePicker(!0, !0);
      const d = n.flow[Y.value];
      (d === "hours" || d === "minutes" || d === "seconds") && h.value && h.value.toggleTimePicker(!0, !0, d);
    }, Pt = (d) => {
      if (T != null && T.value) {
        if (d === "up")
          return U();
        if (d === "down")
          return _();
        if (d === "left")
          return J();
        if (d === "right")
          return F();
      } else
        d === "left" || d === "up" ? ot("left", 0, d === "up") : ot("right", 0, d === "down");
    }, ka = (d) => {
      l(d.shiftKey), !n.disableMonthYearSelect && d.code === "Tab" && d.target.classList.contains("dp__menu") && o().shiftKeyInMenu && (d.preventDefault(), d.stopImmediatePropagation(), a("closePicker"));
    };
    return (d, G) => (g(), le(yt, {
      appear: "",
      name: u(O).menuAppear,
      mode: "out-in",
      css: !!u(O)
    }, {
      default: ae(() => [
        W("div", {
          id: d.uid ? `dp-menu-${d.uid}` : void 0,
          tabindex: "0",
          ref_key: "dpMenuRef",
          ref: M,
          role: "dialog",
          "aria-label": u(L).menu,
          class: fe(u(va)),
          onMouseleave: G[12] || (G[12] = (...H) => u(Ke) && u(Ke)(...H)),
          onClick: ha,
          onKeydown: [
            de(ga, ["esc"]),
            de(wa, ["space"]),
            G[13] || (G[13] = de(Ue((H) => Pt("left"), ["prevent"]), ["left"])),
            G[14] || (G[14] = de(Ue((H) => Pt("up"), ["prevent"]), ["up"])),
            G[15] || (G[15] = de(Ue((H) => Pt("down"), ["prevent"]), ["down"])),
            G[16] || (G[16] = de(Ue((H) => Pt("right"), ["prevent"]), ["right"])),
            ka
          ]
        }, [
          (d.disabled || d.readonly) && d.inline ? (g(), S("div", {
            key: 0,
            class: fe(u(ca))
          }, null, 2)) : b("", !0),
          !d.inline && !d.teleportCenter ? (g(), S("div", {
            key: 1,
            class: fe(u(r))
          }, null, 2)) : b("", !0),
          W("div", {
            class: fe(d.presetRanges.length ? "dp__menu_content_wrapper" : null)
          }, [
            d.presetRanges.length ? (g(), S("div", Ur, [
              (g(!0), S(se, null, ye(d.presetRanges, (H, q) => (g(), S("div", {
                key: q,
                style: ft(H.style || {}),
                class: "dp__preset_range",
                onClick: (x) => u(ut)(H.range)
              }, ge(H.label), 13, Lr))), 128))
            ])) : b("", !0),
            W("div", {
              class: "dp__instance_calendar",
              ref_key: "calendarWrapperRef",
              ref: c,
              role: "document"
            }, [
              W("div", {
                class: fe(u(We))
              }, [
                (g(!0), S(se, null, ye(u(xe), (H, q) => (g(), S("div", {
                  key: H,
                  class: fe(u(da))
                }, [
                  !d.disableMonthYearSelect && !d.timePicker ? (g(), le(Vt(d.monthYearComponent ? d.monthYearComponent : Kr), Ae({
                    key: 0,
                    ref_for: !0,
                    ref: (x) => {
                      x && (v.value[q] = x);
                    }
                  }, {
                    months: u(A),
                    years: u(k),
                    filters: d.filters,
                    monthPicker: d.monthPicker,
                    month: u(i)(H),
                    year: u(s)(H),
                    customProps: d.customProps,
                    multiCalendars: e.multiCalendars,
                    multiCalendarsSolo: d.multiCalendarsSolo,
                    instance: H,
                    minDate: d.minDate,
                    maxDate: d.maxDate,
                    preventMinMaxNavigation: d.preventMinMaxNavigation,
                    internalModelValue: e.internalModelValue,
                    range: d.range,
                    reverseYears: d.reverseYears,
                    vertical: d.vertical,
                    yearPicker: d.yearPicker
                  }, {
                    onMount: G[0] || (G[0] = (x) => jt("monthYearInput")),
                    onResetFlow: Q,
                    onUpdateMonthYear: (x) => u(D)(H, x),
                    onMonthYearSelect: u(Ze),
                    onOverlayClosed: z
                  }), Pe({ _: 2 }, [
                    ye(u(At), (x, wt) => ({
                      name: x,
                      fn: ae((et) => [
                        K(d.$slots, x, Le(nt(et)))
                      ])
                    }))
                  ]), 1040, ["onUpdateMonthYear", "onMonthYearSelect"])) : b("", !0),
                  je(nr, Ae({
                    ref_for: !0,
                    ref: (x) => {
                      x && (y.value[q] = x);
                    }
                  }, u(ma), {
                    "flow-step": Y.value,
                    "onUpdate:flow-step": G[1] || (G[1] = (x) => Y.value = x),
                    instance: H,
                    "mapped-dates": u(fa)(H),
                    month: u(i)(H),
                    year: u(s)(H),
                    onSelectDate: (x) => u(p)(x, !u(Te)(H)),
                    onSetHoverDate: G[2] || (G[2] = (x) => u(N)(x)),
                    onHandleScroll: (x) => u(be)(x, H),
                    onHandleSwipe: (x) => u(pt)(x, H),
                    onMount: G[3] || (G[3] = (x) => jt("calendar")),
                    onResetFlow: Q
                  }), Pe({ _: 2 }, [
                    ye(u(Qe), (x, wt) => ({
                      name: x,
                      fn: ae((et) => [
                        K(d.$slots, x, Le(nt({ ...et })))
                      ])
                    }))
                  ]), 1040, ["flow-step", "instance", "mapped-dates", "month", "year", "onSelectDate", "onHandleScroll", "onHandleSwipe"])
                ], 2))), 128))
              ], 2),
              W("div", null, [
                d.enableTimePicker && !d.monthPicker && !d.weekPicker ? (g(), le(Vt(d.timePickerComponent ? d.timePickerComponent : Rr), Ae({
                  key: 0,
                  ref_key: "timePickerRef",
                  ref: h
                }, {
                  is24: d.is24,
                  hoursIncrement: d.hoursIncrement,
                  minutesIncrement: d.minutesIncrement,
                  hoursGridIncrement: d.hoursGridIncrement,
                  secondsIncrement: d.secondsIncrement,
                  minutesGridIncrement: d.minutesGridIncrement,
                  secondsGridIncrement: d.secondsGridIncrement,
                  noHoursOverlay: d.noHoursOverlay,
                  noMinutesOverlay: d.noMinutesOverlay,
                  noSecondsOverlay: d.noSecondsOverlay,
                  range: d.range,
                  filters: d.filters,
                  timePicker: d.timePicker,
                  hours: u(w),
                  minutes: u(E),
                  seconds: u(ee),
                  customProps: d.customProps,
                  enableSeconds: d.enableSeconds,
                  fixedStart: d.fixedStart,
                  fixedEnd: d.fixedEnd,
                  modelAuto: d.modelAuto,
                  internalModelValue: e.internalModelValue
                }, {
                  onMount: G[4] || (G[4] = (H) => jt("timePicker")),
                  "onUpdate:hours": G[5] || (G[5] = (H) => u(j)(H)),
                  "onUpdate:minutes": G[6] || (G[6] = (H) => u(j)(H, !1)),
                  "onUpdate:seconds": G[7] || (G[7] = (H) => u(j)(H, !1, !0)),
                  onResetFlow: Q,
                  onOverlayClosed: z
                }), Pe({ _: 2 }, [
                  ye(u(Gt), (H, q) => ({
                    name: H,
                    fn: ae((x) => [
                      K(d.$slots, H, Le(nt(x)))
                    ])
                  }))
                ]), 1040)) : b("", !0)
              ])
            ], 512),
            d.showNowButton ? (g(), S("div", Gr, [
              d.$slots["now-button"] ? K(d.$slots, "now-button", {
                key: 0,
                selectCurrentDate: u(f)
              }) : b("", !0),
              d.$slots["now-button"] ? b("", !0) : (g(), S("button", {
                key: 1,
                type: "button",
                role: "button",
                class: "dp__now_button",
                onClick: G[8] || (G[8] = (...H) => u(f) && u(f)(...H))
              }, ge(d.nowButtonLabel), 1))
            ])) : b("", !0)
          ], 2),
          !d.autoApply || d.keepActionRow ? (g(), le(Vt(d.actionRowComponent ? d.actionRowComponent : mr), Ae({ key: 2 }, {
            calendarWidth: R.value,
            selectText: d.selectText,
            cancelText: d.cancelText,
            internalModelValue: e.internalModelValue,
            range: d.range,
            previewFormat: d.previewFormat,
            inline: d.inline,
            monthPicker: d.monthPicker,
            timePicker: d.timePicker,
            customProps: d.customProps,
            multiCalendars: e.multiCalendars,
            menuMount: B.value,
            maxTime: d.maxTime,
            minTime: d.minTime,
            enableTimePicker: d.enableTimePicker,
            minDate: d.minDate,
            maxDate: d.maxDate,
            multiDates: d.multiDates,
            modelAuto: d.modelAuto,
            partialRange: d.partialRange
          }, {
            onClosePicker: G[9] || (G[9] = (H) => d.$emit("closePicker")),
            onSelectDate: G[10] || (G[10] = (H) => d.$emit("selectDate")),
            onInvalidSelect: G[11] || (G[11] = (H) => d.$emit("invalid-select"))
          }), Pe({ _: 2 }, [
            ye(u(Lt), (H, q) => ({
              name: H,
              fn: ae((x) => [
                K(d.$slots, H, Le(nt({ ...x })))
              ])
            }))
          ]), 1040)) : b("", !0)
        ], 42, Wr)
      ]),
      _: 3
    }, 8, ["name", "css"]));
  }
});
var Yt = /* @__PURE__ */ ((e) => (e.center = "center", e.left = "left", e.right = "right", e))(Yt || {});
const zr = (e, a, n, t, c, m, v, y, h) => {
  const M = V({
    top: "0",
    left: "0",
    transform: "none"
  }), R = V(!1), B = 390, Y = (_) => {
    const U = _.getBoundingClientRect();
    return {
      left: U.left + window.scrollX,
      top: U.top + window.scrollY
    };
  }, O = (_) => {
    const U = _.getBoundingClientRect();
    let z = 0, Z = 0;
    for (; _ && !isNaN(_.offsetLeft) && !isNaN(_.offsetTop); )
      z += _.offsetLeft - _.scrollLeft, Z = U.top + _.scrollTop, _ = _.offsetParent;
    return { top: Z, left: z };
  }, L = (_, U) => {
    M.value.left = `${_ + U}px`, M.value.transform = "translateX(-100%)";
  }, T = (_) => {
    M.value.left = `${_}px`, M.value.transform = "translateX(0)";
  }, te = (_, U) => {
    e === Yt.left && T(_), e === Yt.right && L(_, U), e === Yt.center && (M.value.left = `${_ + U / 2}px`, M.value.transform = "translateX(-50%)");
  }, l = () => {
    const _ = we(c);
    if (_) {
      const U = window.innerHeight, { top: z } = a ? O(_) : Y(_), { left: Z, width: Q, top: j, height: D } = _.getBoundingClientRect(), $ = U - j - D;
      M.value.top = j > $ ? `${z - B}px` : `${z}px`, te(Z, Q);
    }
  }, o = () => {
    M.value.left = "50%", M.value.top = "50%", M.value.transform = "translate(-50%, -50%)", M.value.position = "fixed";
  }, F = (_ = !0) => {
    if (!m) {
      if (y.value)
        return o();
      const U = we(c);
      if (a && typeof a != "boolean")
        M.value = a(U);
      else if (U) {
        const { left: z, width: Z, height: Q } = U.getBoundingClientRect(), { top: j } = a ? O(U) : Y(U);
        M.value.top = `${Q + j + +v}px`, te(z, Z), _ && n && J();
      }
    }
  }, J = () => {
    const _ = we(c);
    if (_ && n && !m) {
      const { height: U, top: z, left: Z, width: Q } = _.getBoundingClientRect(), { top: j } = a ? O(_) : Y(_), $ = window.innerHeight - z - U, i = we(t);
      if (i) {
        const { height: s, left: w, right: E } = i.getBoundingClientRect(), ee = s + U;
        ee > z && ee > $ ? z < $ ? (F(!1), R.value = !1) : (M.value.top = `${j - s - +v}px`, R.value = !0) : ee > $ ? (M.value.top = `${j - s - +v}px`, R.value = !0) : (F(!1), R.value = !1), w < 0 ? T(Z) : E > document.documentElement.clientWidth && L(Z, Q);
      }
    }
    h("recalculatePosition");
  };
  return { openOnTop: R, menuPosition: M, setMenuPosition: F, setInitialPosition: l, recalculatePosition: J };
}, Xr = (e, a, n, t, c, m, v, y, h, M, R, B, Y, O, L, T, te) => {
  const l = V(""), o = V();
  Mt(o, () => {
    te("internalModelChange", o.value);
  });
  const F = (i) => [Z(i[0]), i[1] ? Z(i[1]) : null], J = (i) => {
    let s = null;
    i ? a ? lr(i) && "hours" in i[0] && "minutes" in i[0] ? s = [
      Se(null, +i[0].hours, +i[0].minutes, +i[0].seconds),
      Se(null, +i[1].hours, +i[1].minutes, +i[1].seconds)
    ] : ar(i) && (s = Se(null, +i.hours, +i.minutes, +i.seconds)) : n ? rr(i) && "month" in i[0] && "year" in i[0] ? (s = [rt(null, +i[0].month, +i[0].year)], i[1] ? s[1] = rt(null, +i[1].month, +i[1].year) : !i[1] && c && (s[1] = null)) : sr(i) && "month" in i && "year" in i && (s = rt(null, +i.month, +i.year)) : Y ? Array.isArray(i) ? s = [
      lt(new Date(), i[0]),
      !i[1] && c ? null : lt(new Date(), i[1])
    ] : s = lt(new Date(), i) : M && Array.isArray(i) ? s = i.map((w) => Z(w)) : B && Array.isArray(i) ? s = [new Date(i[0]), new Date(i[1])] : t ? T ? Array.isArray(i) ? s = F(i) : s = [Z(i), null] : or(i, c) && (s = F(i)) : ir(i) && (s = Z(i)) : s = null, rn(s) ? (o.value = s, U()) : (o.value = null, l.value = "");
  }, _ = () => Gn(
    e,
    m,
    y,
    n,
    a,
    B,
    Y,
    v
  ), U = () => {
    if (!o.value)
      l.value = "";
    else if (!e || typeof e == "string") {
      const i = _();
      Array.isArray(o.value) && M ? l.value = o.value.map((s) => mt(s, i, h == null ? void 0 : h.value)).join("; ") : l.value = mt(
        o.value,
        i,
        h == null ? void 0 : h.value,
        O == null ? void 0 : O.rangeSeparator,
        T
      );
    } else
      a ? l.value = e(sn(o.value)) : n ? l.value = e(Cn(o.value)) : l.value = e(o.value);
  }, z = () => o.value ? t ? c ? o.value.length >= 1 : o.value.length === 2 : !!o.value : !1, Z = (i) => {
    if (R) {
      const s = new Date(i);
      return R === "preserve" ? new Date(s.getTime() + s.getTimezoneOffset() * 6e4) : s;
    }
    return L ? L === "date" || L === "timestamp" ? new Date(i) : L === "format" && (typeof e == "string" || !e) ? ln(i, _(), new Date()) : ln(i, L, new Date()) : new Date(i);
  }, Q = (i) => L ? L === "timestamp" ? +i : L === "format" && (typeof e == "string" || !e) ? mt(i, _(), h == null ? void 0 : h.value, O == null ? void 0 : O.rangeSeparator) : mt(i, L, h == null ? void 0 : h.value, O == null ? void 0 : O.rangeSeparator) : i, j = (i) => {
    te("update:modelValue", i);
  }, D = () => [
    Q(o.value[0]),
    o.value[1] ? Q(o.value[1]) : null
  ];
  return {
    parseExternalModelValue: J,
    formatInputValue: U,
    internalModelValue: o,
    inputValue: l,
    emitModelValue: () => {
      if (n)
        j(Cn(o.value));
      else if (a)
        j(sn(o.value));
      else if (B)
        j(o.value);
      else if (Y)
        j(
          Array.isArray(o.value) ? [
            ie(o.value[0]),
            o.value[1] ? ie(o.value[1]) : null
          ] : ie(o.value)
        );
      else {
        if (o.value && t && c && o.value.length === 1 && o.value.push(null), R) {
          let i;
          if (Array.isArray(o.value)) {
            const s = (w) => w && Zt(w, R === "preserve");
            T ? i = o.value[1] ? o.value.map(s) : Zt(o.value[0], R === "preserve") : i = o.value.map(s);
          } else
            i = Zt(o.value, R === "preserve");
          return j(i);
        }
        Array.isArray(o.value) && !M ? j(
          T ? o.value[1] ? D() : Q(o.value[0]) : D()
        ) : Array.isArray(o.value) && M ? j(o.value.map((i) => Q(i))) : j(Q(o.value));
      }
      U();
    },
    checkBeforeEmit: z
  };
}, qr = typeof window < "u" ? window : void 0, an = () => {
}, Jr = (e) => Ma() ? (Sa(e), !0) : !1, Zr = (e, a, n, t) => {
  if (!e)
    return an;
  let c = an;
  const m = Mt(
    () => u(e),
    (y) => {
      c(), y && (y.addEventListener(a, n, t), c = () => {
        y.removeEventListener(a, n, t), c = an;
      });
    },
    { immediate: !0, flush: "post" }
  ), v = () => {
    m(), c();
  };
  return Jr(v), v;
}, Qr = (e, a, n, t = {}) => {
  const { window: c = qr, event: m = "pointerdown" } = t;
  return c ? Zr(c, m, (y) => {
    const h = we(e), M = we(a);
    !h || !M || h === y.target || y.composedPath().includes(h) || y.composedPath().includes(M) || n(y);
  }, { passive: !0 }) : void 0;
}, xr = /* @__PURE__ */ Be({
  __name: "VueDatePicker",
  props: {
    ...Cl
  },
  emits: [
    "update:modelValue",
    "textSubmit",
    "closed",
    "cleared",
    "open",
    "focus",
    "blur",
    "internalModelChange",
    "recalculatePosition",
    "flow-step",
    "updateMonthYear",
    "invalid-select"
  ],
  setup(e, { expose: a, emit: n }) {
    const t = e, c = dn(), m = V(!1), v = Ct(t, "modelValue"), y = V(null), h = V(null), M = Ct(t, "teleportCenter");
    dt(vn, t.autoApply);
    const R = C(() => t.formatLocale);
    dt(Jn, R), dt(qn, Ct(t, "textInput")), dt(Xe, Ct(t, "arrowNavigation")), Oe(() => {
      J(t.modelValue), t.inline || (window.addEventListener("scroll", P), window.addEventListener("resize", N)), t.inline && (m.value = !0);
    }), un(() => {
      t.inline || (window.removeEventListener("scroll", P), window.removeEventListener("resize", N));
    });
    const B = at(c, "all"), Y = at(c, "input");
    Mt(
      v,
      () => {
        J(v.value);
      },
      { deep: !0 }
    );
    const { openOnTop: O, menuPosition: L, setMenuPosition: T, recalculatePosition: te, setInitialPosition: l } = zr(
      t.position,
      t.altPosition,
      t.autoPosition,
      y,
      h,
      t.inline,
      t.offset,
      M,
      n
    ), {
      internalModelValue: o,
      inputValue: F,
      parseExternalModelValue: J,
      emitModelValue: _,
      checkBeforeEmit: U,
      formatInputValue: z
    } = Xr(
      t.format,
      t.timePicker,
      t.monthPicker,
      t.range,
      t.partialRange,
      t.is24,
      t.enableTimePicker,
      t.enableSeconds,
      R,
      t.multiDates,
      t.utc,
      t.weekPicker,
      t.yearPicker,
      t.textInputOptions,
      t.modelType,
      t.modelAuto,
      n
    ), { clearArrowNav: Z } = qe(), { setMenuFocused: Q, setShiftKey: j } = ua(), D = C(
      () => ({
        dp__main: !0,
        dp__theme_dark: t.dark,
        dp__theme_light: !t.dark,
        dp__flex_display: t.inline,
        dp__flex_display_with_input: t.inlineWithInput
      })
    ), $ = C(() => Vn(t.format) ? t.format : Gn(
      null,
      t.is24,
      t.enableSeconds,
      t.monthPicker,
      t.timePicker,
      t.weekPicker,
      t.yearPicker,
      t.enableTimePicker
    )), i = C(() => t.previewFormat ? t.previewFormat : Vn($.value) ? $.value : t.format), s = C(() => typeof t.transitions == "boolean" ? t.transitions ? _n({}) : !1 : _n(t.transitions));
    dt(Wt, s);
    const w = C(() => t.dark ? "dp__theme_dark" : "dp__theme_light"), E = C(() => Object.assign(Wl(), t.textInputOptions)), ee = C(() => jl(t.ariaLabels));
    dt(ze, ee);
    const I = C(() => Ul(t.filters)), re = C(() => {
      const f = (ne) => {
        const ce = {
          hours: Re(new Date()),
          minutes: Ve(new Date()),
          seconds: Ge(new Date())
        };
        return Object.assign(ce, ne);
      };
      return t.range ? t.startTime && Array.isArray(t.startTime) ? [f(t.startTime[0]), f(t.startTime[1])] : null : t.startTime && !Array.isArray(t.startTime) ? f(t.startTime) : null;
    }), p = C(() => t.multiCalendars === null ? 0 : typeof t.multiCalendars == "boolean" ? t.multiCalendars ? 2 : 0 : +t.multiCalendars >= 2 ? +t.multiCalendars : 2), P = () => {
      m.value && (t.closeOnScroll ? be() : t.autoPosition ? T() : window.removeEventListener("scroll", P));
    }, N = () => {
      m.value && T();
    }, pe = () => {
      !t.disabled && !t.readonly && (l(), m.value = !0, St().then(() => {
        T(), m.value && n("open");
      }), m.value || Ze(), J(t.modelValue));
    }, Ee = () => {
      F.value = "", Ze(), n("update:modelValue", null), n("cleared"), be();
    }, Je = () => {
      const { validate: f } = Xn(
        t.minDate,
        t.maxDate,
        t.disabledDates,
        t.allowedDates,
        I.value,
        t.disabledWeekDays,
        t.yearRange
      ), ne = o.value;
      return !ne || !Array.isArray(ne) && f(ne) ? !0 : Array.isArray(ne) ? ne.length === 2 && f(ne[0]) && f(ne[1]) ? !0 : !!f(ne[0]) : !1;
    }, Ne = () => {
      U() && Je() ? (_(), be()) : n("invalid-select", o.value);
    }, Ke = (f) => {
      _(), t.closeOnAutoApply && !f && be();
    }, He = (f = !1) => {
      t.autoApply && (!t.enableTimePicker || t.monthPicker || t.yearPicker ? !0 : on(o.value, t.maxTime, t.minTime, t.maxDate, t.minDate)) && Je() && (t.range && Array.isArray(o.value) ? (t.partialRange || o.value.length === 2) && Ke(f) : Ke(f));
    }, Ze = () => {
      o.value = null;
    }, be = () => {
      t.inline || (m.value && (m.value = !1, Q(!1), j(!1), Z(), n("closed"), l(), F.value && J(v.value)), Ze(), h.value && h.value.focusInput());
    }, ot = (f, ne) => {
      if (!f) {
        o.value = null;
        return;
      }
      o.value = f, ne && (Ne(), n("textSubmit"));
    }, pt = () => {
      t.autoApply && on(o.value, t.maxTime, t.minTime, t.maxDate, t.minDate) && _();
    }, ht = () => m.value ? be() : pe();
    return Qr(y, h, be), a({
      closeMenu: be,
      selectDate: Ne,
      clearValue: Ee,
      openMenu: pe,
      onScroll: P,
      formatInputValue: z
    }), (f, ne) => (g(), S("div", {
      class: fe(u(D))
    }, [
      je(Ol, Ae({
        ref_key: "inputRef",
        ref: h
      }, {
        placeholder: f.placeholder,
        hideInputIcon: f.hideInputIcon,
        readonly: f.readonly,
        disabled: f.disabled,
        inputClassName: f.inputClassName,
        clearable: f.clearable,
        state: f.state,
        inline: f.inline,
        inlineWithInput: f.inlineWithInput,
        textInput: f.textInput,
        textInputOptions: u(E),
        range: f.range,
        isMenuOpen: m.value,
        pattern: u($),
        autoApply: f.autoApply,
        uid: f.uid,
        required: f.required,
        name: f.name,
        autocomplete: f.autocomplete
      }, {
        "input-value": u(F),
        "onUpdate:input-value": ne[0] || (ne[0] = (ce) => tt(F) ? F.value = ce : null),
        onClear: Ee,
        onOpen: pe,
        onSetInputDate: ot,
        onSetEmptyDate: u(_),
        onSelectDate: Ne,
        onToggle: ht,
        onClose: be,
        onFocus: ne[1] || (ne[1] = (ce) => f.$emit("focus")),
        onBlur: ne[2] || (ne[2] = (ce) => f.$emit("blur"))
      }), Pe({ _: 2 }, [
        ye(u(Y), (ce, ut) => ({
          name: ce,
          fn: ae((Qe) => [
            K(f.$slots, ce, Le(nt(Qe)))
          ])
        }))
      ]), 1040, ["input-value", "onSetEmptyDate"]),
      m.value ? (g(), le(Aa, {
        key: 0,
        to: f.teleport,
        disabled: f.inline
      }, [
        m.value ? (g(), le(jr, Ae({
          key: 0,
          ref_key: "dpMenuRef",
          ref: y,
          class: u(w),
          style: u(L)
        }, {
          weekNumbers: f.weekNumbers,
          weekStart: f.weekStart,
          disableMonthYearSelect: f.disableMonthYearSelect,
          menuClassName: f.menuClassName,
          calendarClassName: f.calendarClassName,
          yearRange: f.yearRange,
          range: f.range,
          multiCalendars: u(p),
          multiCalendarsSolo: f.multiCalendarsSolo,
          multiStatic: f.multiStatic,
          calendarCellClassName: f.calendarCellClassName,
          enableTimePicker: f.enableTimePicker,
          is24: f.is24,
          hoursIncrement: f.hoursIncrement,
          minutesIncrement: f.minutesIncrement,
          hoursGridIncrement: f.hoursGridIncrement,
          minutesGridIncrement: f.minutesGridIncrement,
          minDate: f.minDate,
          maxDate: f.maxDate,
          autoApply: f.autoApply,
          selectText: f.selectText,
          cancelText: f.cancelText,
          previewFormat: u(i),
          locale: f.locale,
          weekNumName: f.weekNumName,
          disabledDates: f.disabledDates,
          filters: u(I),
          minTime: f.minTime,
          maxTime: f.maxTime,
          inline: f.inline,
          openOnTop: u(O),
          monthPicker: f.monthPicker,
          timePicker: f.timePicker,
          monthNameFormat: f.monthNameFormat,
          startDate: f.startDate,
          startTime: u(re),
          monthYearComponent: f.monthYearComponent,
          timePickerComponent: f.timePickerComponent,
          actionRowComponent: f.actionRowComponent,
          customProps: f.customProps,
          hideOffsetDates: f.hideOffsetDates,
          autoRange: f.autoRange,
          noToday: f.noToday,
          noHoursOverlay: f.noHoursOverlay,
          noMinutesOverlay: f.noMinutesOverlay,
          disabledWeekDays: f.disabledWeekDays,
          allowedDates: f.allowedDates,
          showNowButton: f.showNowButton,
          nowButtonLabel: f.nowButtonLabel,
          monthChangeOnScroll: f.monthChangeOnScroll,
          markers: f.markers,
          uid: f.uid,
          modeHeight: f.modeHeight,
          enableSeconds: f.enableSeconds,
          secondsIncrement: f.secondsIncrement,
          secondsGridIncrement: f.secondsGridIncrement,
          noSecondsOverlay: f.noSecondsOverlay,
          escClose: f.escClose,
          spaceConfirm: f.spaceConfirm,
          monthChangeOnArrows: f.monthChangeOnArrows,
          textInput: f.textInput,
          disabled: f.disabled,
          readonly: f.readonly,
          multiDates: f.multiDates,
          presetRanges: f.presetRanges,
          flow: f.flow,
          preventMinMaxNavigation: f.preventMinMaxNavigation,
          minRange: f.minRange,
          maxRange: f.maxRange,
          fixedStart: f.fixedStart,
          fixedEnd: f.fixedEnd,
          multiDatesLimit: f.multiDatesLimit,
          reverseYears: f.reverseYears,
          keepActionRow: f.keepActionRow,
          weekPicker: f.weekPicker,
          noSwipe: f.noSwipe,
          vertical: f.vertical,
          arrowNavigation: f.arrowNavigation,
          yearPicker: f.yearPicker,
          disableTimeRangeValidation: f.disableTimeRangeValidation,
          dayNames: f.dayNames,
          modelAuto: f.modelAuto,
          highlight: f.highlight,
          partialRange: f.partialRange,
          teleportCenter: f.teleportCenter
        }, {
          internalModelValue: u(o),
          "onUpdate:internalModelValue": ne[3] || (ne[3] = (ce) => tt(o) ? o.value = ce : null),
          onClosePicker: be,
          onSelectDate: Ne,
          onDpOpen: u(te),
          onAutoApply: He,
          onTimeUpdate: pt,
          onFlowStep: ne[4] || (ne[4] = (ce) => f.$emit("flow-step", ce)),
          onUpdateMonthYear: ne[5] || (ne[5] = (ce) => f.$emit("updateMonthYear", ce)),
          onInvalidSelect: ne[6] || (ne[6] = (ce) => f.$emit("invalid-select", u(o)))
        }), Pe({ _: 2 }, [
          ye(u(B), (ce, ut) => ({
            name: ce,
            fn: ae((Qe) => [
              K(f.$slots, ce, Le(nt({ ...Qe })))
            ])
          }))
        ]), 1040, ["class", "style", "internalModelValue", "onDpOpen"])) : b("", !0)
      ], 8, ["to", "disabled"])) : b("", !0)
    ], 2));
  }
}), ia = /* @__PURE__ */ (() => {
  const e = xr;
  return e.install = (a) => {
    a.component("VueDatePicker", e);
  }, e;
})(), es = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: ia
}, Symbol.toStringTag, { value: "Module" }));
Object.entries(es).forEach(([e, a]) => {
  e !== "default" && (ia[e] = a);
});
export {
  ia as default
};
 
